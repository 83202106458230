// import { getStorage } from '@/helper/storage';
// import { PROJECT_USER_KEY } from '@/constant/storage';
// import { PLAYBACK_PLATFORM_URL } from '@/constant/index';
// import { reportRecord } from './tianyan'
import { BLANK_WAIT_TIME } from '@/constant/index';
/**
 * 白屏监控
 */
const ERROR_NAME = '_blank_dom_error'
const DEFAULT_OBSERVER_CONFIG = {
  childList: true,
}


/**
 * 获取某个 css 属性值
 * @param domElem dom 元素
 * @param attr css 属性名称
 */
const getCssAttribute = (domElem: HTMLElement, attr: string) => {
  if (!domElem) {
    return ''
  }
  let domStyle = domElem.style
  if (window && window.getComputedStyle) {
    try {
      domStyle = window.getComputedStyle(domElem)
    } catch (e) {
      return ''
    }
  }
  return domStyle ? domStyle.getPropertyValue(attr) : ''
}

// 判断是否是空白文档
const isBlankDom = (domElem: HTMLElement | null) => {
  if (!domElem) return
  // 如果该节点隐藏，则认为是空节点
  if (getCssAttribute(domElem, 'display') === 'none') {
    return true
  }
  // 有子节点则认为非空白文档
  if (domElem?.innerHTML?.trim()) {
    return false
  }
  return true
}
/**
 * 监控页面白屏插件
 */
export default function ravenBlankObserver(raven, pluginOptions) {
  if (!window?.MutationObserver || !raven) {
    return
  }
  // 白屏等待时间
  const sleepTime =
    pluginOptions && pluginOptions.waitingTime
      ? pluginOptions.waitingTime
      : BLANK_WAIT_TIME
  let sleepTimer: any
  let prepareReportTimer: any
  let alreadyReport: Boolean = false
  // 获取要监听的 dom，该 dom 必须是一直存在的
  let observeDom: HTMLElement | null = null
  // 支持直接传递 dom 元素和传 domid 两种方式
  if (typeof pluginOptions.domElem === 'string') {
    observeDom = window.document.getElementById(pluginOptions.domElem)
  } else {
    observeDom = pluginOptions.domElem
  }
  if (!observeDom) {
    return
  }
  // 计时时间名称
  // const trackTimeName = `${pluginOptions.domElem}${TRACK_TIME_APP_MOUNT}`;
  // 发送白屏错误日志
  const send = () => {
    const domHTML = observeDom!.innerHTML || ''
    const logInfo = {
      domElem: JSON.stringify(pluginOptions.domElem),
      time: Date.now(),
      dom_length: domHTML.length,
      dom_content: domHTML.substring(0, 400),
    }
    // const { uid } = getStorage(PROJECT_USER_KEY) || {};

    // const { timestamp } = reportRecord({
    //   eventType: 1,
    //   traceId: null,
    //   reqUrl: null,
    //   respHttpStatus: null,
    //   respErrno: null,
    //   extInfo: logInfo
    // })

    raven.trackError(ERROR_NAME, {
      ...logInfo,
      playbackId: '', //timestamp,
      playbackUrl: '' //`${PLAYBACK_PLATFORM_URL}uid=${uid}&playbackId=${timestamp}`
    })
  }

  // 等待一段时间后如果 dom 是空白的，则上报白屏
  const startObserve = () => {
    sleepTimer = setTimeout(() => {
      if (isBlankDom(observeDom)) {
        prepareReportTimer = setTimeout(() => {
          send();
          alreadyReport = true
        }, 6000);
      }
    }, sleepTime)
  }

  // 监控到 dom 改变则停止监听
  const stopObserve = () => {
    sleepTimer && clearTimeout(sleepTimer)
  }

  const cancelReport = () => {
    prepareReportTimer && clearTimeout(prepareReportTimer)
    if (isBlankDom(observeDom) && !alreadyReport) {
      send();
    }
  }

  try {
    const observer = new (window as any).MutationObserver(() => {
      // 监控到了 dom 有变化，则 stop 上一次监听
      stopObserve()
      // 判断 dom 是否是空白的，如果是空白的，则开启新的监听
      if (isBlankDom(observeDom)) {
        startObserve()
      }
    })
    // 绑定待监听的 dom
    observer?.observe(observeDom, DEFAULT_OBSERVER_CONFIG)
  } catch (error) {
    console.error(error);
  }
  // 开启监听
  startObserve();

  return { stopObserve, cancelReport };
}
