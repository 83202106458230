import { post } from '@service/request';
import { getMaxDepth, getSpecialDepthData } from '@/utils';
import { makeAutoObservable, toJS } from 'mobx';
import { isProxyMode } from '@/constant/frame-work-proxy-key';
import { PROJECT_HOME_PATH, BASE_TABS_STATE } from '@/constant/storage';
import { setSessionStorage, getSessionStorage, removeSessionStorage } from '@/helper/storage';
import { initRobotSDK } from '@/helper/robot';
import { omegaSetConfig, setAttrs, initRavenConfig } from '@/service/omega/tianyan';
import { HOME_PAGE_PATH, HOME_PAGE_TITLE, FIXED_HOME_PAGE_PATH } from '@/constant';
import { message } from 'antd';
import { login } from '@/service/login';

class User {
  constructor() {
    makeAutoObservable(this);
  }

  get HOME_PAGE_APP_NAME() {
    return HOME_PAGE_PATH.split('/')[1];
  }

  HOME_PAGE_PATH = HOME_PAGE_PATH;

  HOME_PAGE_TITLE = HOME_PAGE_TITLE;

  userInfo = {
    authFeatures: {},
    provinceCityTreeList: [],
    siteList: [],
    roleList: [],
    relatedOpenCompanyList: [],
    openCompanyTypeIds: [],
    authUrls: [], // 全量权限未经过处理的
  };

  authMenu = [];

  microRouterList = [];

  loadPathname = []; // 已经加载过得子应用

  updateHomePageInfo({ path, nameCN }) {
    if (!FIXED_HOME_PAGE_PATH && path && nameCN) {
      this.HOME_PAGE_PATH = path;
      this.HOME_PAGE_TITLE = nameCN;
      const lastHomePath = getSessionStorage(PROJECT_HOME_PATH);
      if (lastHomePath && lastHomePath !== path) {
        removeSessionStorage(BASE_TABS_STATE);
      }
      setSessionStorage(PROJECT_HOME_PATH, path);
    }
  }

  setMicroRouterList(list) {
    const pathname = location.pathname;
    if (this.loadPathname?.includes(pathname)) return;
    this.loadPathname.push(pathname);
    this.microRouterList = [...this.microRouterList, ...list];
  }

  async init(localMenus) {
    try {
      const { data, code, msg } = await post('/direct?action=perm_user_getUserAndPerm', {});
      if (code === 200) {
        const { resourceList, provinceList, siteList, roleList, ...userInfo } = data || {};
        const { authFeatures, authMenus } = this.getAuthMenuAndFeatures(resourceList);
        const provinceCityTreeList = this.formatProvinceCityTreeList(provinceList);
        const relatedOpenCompanyList = Array.isArray(userInfo?.relatedOpenCompanyList)
          ? userInfo.relatedOpenCompanyList
          : [];
        const openCompanyTypeIds = [
          ...new Set(relatedOpenCompanyList.map(item => item?.openCompanyType) || []),
        ];
        this.userInfo = {
          ...userInfo,
          provinceCityTreeList,
          siteList,
          authFeatures,
          roleList: roleList?.map(item => item?.roleList || [])?.flat() || [],
          relatedOpenCompanyList,
          openCompanyTypeIds,
          authUrls: this.getFlatAuthFeatures(resourceList)?.map(item => item?.url),
        };

        const [firstItem, ...rst] = toJS(this.userInfo)?.roleList;
        let other_role_id = '';
        let other_role_name = '';
        rst?.map(a => {
          other_role_id += `${other_role_id && '、'}${a.roleId}`;
          other_role_name += `${other_role_name && '、'}${a.roleName}`;
          return a;
        });
        omegaSetConfig({
          // menu_name,
          open_company_id: this?.userInfo?.cid,
          open_company_name: this?.userInfo?.cidName,
          other_role_id: other_role_id,
          other_role_name: other_role_name,
          role_id: firstItem?.roleId,
          role_name: firstItem?.roleName,
          uid: this?.userInfo?.userId,
          user_name: this?.userInfo?.name,
          phone: this.userInfo?.phone,
        });
        initRavenConfig({
          userId: this?.userInfo?.userId,
          phone: this.userInfo?.phone,
          userName: this?.userInfo?.name,
          c_agg: `${this?.userInfo?.cidName}(${this?.userInfo?.cid})`,
        });

        setAttrs({
          open_company_id: this?.userInfo?.cid,
          open_company_name: this?.userInfo?.cidName,
          other_role_id: other_role_id,
          other_role_name: other_role_name,
          role_id: firstItem?.roleId,
          role_name: firstItem?.roleName,
        });
        initRobotSDK(); // 初始化聊天sdk
        // 通用接口权限菜单，这个默认前端隐藏掉，配置时需要给所有用户新增这个页面权限，通用接口都挂在这个页面下面
        this.authMenu = (isProxyMode ? localMenus : authMenus)?.filter(
          item => item.path !== '/permissions/#/common'
        );
        if (isProxyMode) {
          this.updateHomePageInfo(this.authMenu?.[0]?.children?.[0] || {}); // 本地默认访问当前子应用的第一个菜单
        }
        return data;
      }
      return message.error(msg || '拉取用户信息接口异常');
    } catch (error) {
      console.log(error);
      const { code, msg } = error?.data || {};
      if (code === 999322) {
        message.error('当前合伙人已解约，如有疑问，请联系客户经理');
        const timer = setTimeout(() => {
          login();
          clearTimeout(timer);
        }, 3000);
        return;
      }
      if (code === 999312) {
        return message.error('当前账号登录异常，请重新登录或联系公司负责人、客服');
      }
      return message.error(msg || '拉取用户信息接口异常');
    }
  }

  formatProvinceCityTreeList(provinceList) {
    return provinceList.map(item => {
      const { id, name, cities } = item;
      const children = cities?.map(city => ({
        id: city.cityId,
        name: city.cityName,
        provinceId: city.provinceId,
      }));
      return {
        id,
        name,
        children,
      };
    });
  }

  getAuthMenuAndFeatures(resourceList) {
    return {
      authMenus: this.getAuthMenus(resourceList),
      authFeatures: this.getFeatures(resourceList),
    };
  }

  getAuthMenus(resourceList) {
    const getMenus = menuList => {
      if (!Array.isArray(menuList)) return [];
      return menuList.reduce((r, c) => {
        const { type, leafNodes, url, name } = c;
        if (type === 1 && url) {
          r.push({ path: url, name, nameCN: name, children: getMenus(leafNodes) });
        }
        return r;
      }, []);
    };
    const authMenus = getMenus(resourceList);
    const maxDepth = getMaxDepth(authMenus);
    return getSpecialDepthData(authMenus, 2, maxDepth) || [];
  }

  getFlatAuthFeatures(resourceList) {
    if (!Array.isArray(resourceList)) return [];
    return resourceList.reduce((r, c) => {
      const { type, leafNodes, ...others } = c;
      if (type === 2) {
        r.push(others);
      }
      r.push(...this.getFlatAuthFeatures(leafNodes));
      return r;
    }, []);
  }

  isAuthUrl(url) {
    return url?.includes('_auth_') || url?.includes('?auth=');
  }

  getFeatures(resourceList) {
    const features = this.getFlatAuthFeatures(resourceList);
    if (!Array.isArray(features)) return {};
    return features
      .filter(item => item.url && this.isAuthUrl(item?.url) && !item.url?.startsWith('/gateway'))
      .reduce((map, feature) => {
        const url = feature?.url || '';
        if (url?.includes('?auth=')) {
          // 兼容596里面类似这种的权限点 /596-fe/xxx/xxx.node?auth=xxx, 只要符合这种都可以从596feAuthType取到
          if (!Array.isArray(map['596feAuthType'])) map['596feAuthType'] = [];
          map['596feAuthType'].push(url);
        } else {
          const { 1: pageKey, 2: featureKey } = /(.*)_auth_(.*)/.exec(url) || {};
          if (pageKey && featureKey) {
            if (!Array.isArray(map[pageKey])) map[pageKey] = [];
            map[pageKey].push(featureKey);
          }
        }
        return map;
      }, {});
  }
}

export default new User();
