// import { isProxyMode } from './frame-work-proxy-key'
export const getMicroAppName = () => window.location.pathname.replace(/\//g, '') || 'main'; // 通过一级路由获取当前子应用英文标识
export const TRACK_MANIFEST_SOURCE_ERROR_NAME = 'main_monitor_manifest_source_error_action'; // 请求子应用manifest
export const TRACK_MANIFEST_SOURCE_INFO_NAME = 'main_monitor_manifest_source_info_action';
export const TRACK_APP_SOURCE_ERROR_NAME = 'main_monitor_app_source_error_action'; // 基座初始化子应用资源失败
export const TRACK_APP_SOURCE_INFO_NAME = 'main_monitor_app_source_info_action';
export const TRACK_ERROR_NAME = 'main_monitor_error_action';
export const TRACK_INFO_NAME = 'main_monitor_info_action';
export const TRACK_ERROR_RESPONSE = `error_response`; // 请求相应错误
export const TRACK_NETWORK_ERROR = `network_error`; // 请求网络错误
export const TRACK_TIMEOUT_ERROR = `timeout_error`; // 请求网络错误
export const TRACK_BUTTON_PRIMARY_CLICK_FUN = () => `${getMicroAppName()}_monitor_button_primary_click`; // 全局主按钮点击
export const TRACK_INFO_ROUTE_FUN = () => `${getMicroAppName()}_monitor_route`; // 全局路由
export const TRACK_BUTTON_CLICK_OMEGA = 'freight_parter_huoyun_operate_ck'; // 货运通页面操作按钮点击
export const TRACK_APP_LIFE = `_monitor_app_life`; // 子应用生命周期 `[appname]_monitor_app_life`
// export const TRACK_APP_MOUNTED = `_monitor_mounted`; // 子应用加载 `[appname]_monitor_mounted`
// export const TRACK_APP_UNMOUNT = `_monitor_unmounted`; // 子应用卸载`[appname]_monitor_unmounted`
export const TRACK_TIME_APP_MOUNT = '_monitor_app_time'; // 子应用加载时长，从路由切换到mounted`[appname]_monitor_app_time`
export const TRACK_STATIC_JS_ERROR = 'main_monitor_static_js_error'; // 静态资源error
export const PLAYBACK_PLATFORM_URL = 'https://wj-works.chengxinyouxuan.com/rrweb/replay?'; // 回放平台 
export const PLAYBACK_TEST_PLATFORM_URL = 'http://abc-test04.chengxinyouxuan.com/rrweb/replay?'; // 回放测试平台
export const TRACK_BROWSER_VERSION = 'tech_pc_browser_version'; // 浏览器版本分布
export const TRACK_USER_OPERATE_TIME = 'tech_pc_user_operate_time'; // 用户操作时长统计
export const TRACK_PAGE_VIEW = 'tech_pc_page_view'; // 页面访问统计
export const TRACK_FIRST_APP_LOAD_TIME = 'tech_pc_first_app_load_time'; // 第一个应用加载完成总时长
export const BLANK_WAIT_TIME = 6000;
export const TRACK_PAGELOAD_TIME = 'tech_freight_open_pc_base_pageloadtime';  // 基座加载时长

export const TAB_ACTIVE = true;
export const TAB_INACTIVE = false;

export const HOME_PAGE_PATH = '/employ/#/welcome';
export const HOME_PAGE_TITLE = '运营工作台'
export const HOME_PAGE_APP_NAME = HOME_PAGE_PATH.split('/')[1]

// export const FIXED_HOME_PAGE_PATH = isProxyMode ? false : true

export const FIXED_HOME_PAGE_PATH = false

export const APOLLO_CALLER_NAME = 'mendian'
export const TRACK_MENU_CLICK = 'tmp_menu_click'
export const TRACK_TAB_STATE_CHANGE = 'tab_state_change'
export const TRACK_TAB_STATE_CHANGE_RESULT = 'tab_state_change_result'