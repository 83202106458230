import { FREIGHT_GATEWAY_API_URL, passportConfig, osimHeader } from '@/helper/env';

import { onRequestInterceptor, onResponseInterceptor } from '@/service/omega/tianyan';
const { default: Request } = await import('cx-mf-compo/request');

const successCodeList = [200]; // 成功code列表
const whiteUrlList = ['config.json', 'manifest.json']; // 成功code列表
const BASE_URL = FREIGHT_GATEWAY_API_URL;

const request = new Request({
  baseUrl: BASE_URL,
  externalConfig: {
    useDefaultErrorHandler: false,
    useDefaultRequestInterceptors: false,
    passportConfig,
    wsgConfig: {
      open: true,
      bizId: '2a6ee94215973b235e9d6878b009eedd',
      handleParams: options => {
        /**
         * 货运的请求post的params参数，没有被网络库正常的给加签带给安全
         */
        try {
          const headers = options?.headers || {};
          if (Object.keys(headers).includes('x-citra-source')) {
            const url = options?.url ? options.url : '';
            const urlList = url.includes('action=') ? url.split('action=') : url.split('/');
            const curUrl = urlList[urlList.length - 1];

            const params: any = {};
            if (curUrl) {
              params.action = curUrl;
            }
            return params;
          }
        } catch (error) {
          return {};
        }
      },
    },
  },
  config: {
    retryTimes: 2,
  },
  options: {
    timeout: 60000,
    credentials: 'include',
    requestType: 'json',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      ...osimHeader,
      'x-citra-source': 1,
      'Content-Type': 'application/json;charset=UTF-8',
      'x-embed-sys-id': 'citra',
    },
  },
});

let inited = false;

const init = () => {
  if (inited) {
    return;
  }
  inited = true;
  onRequestInterceptor && request.useRequestInterceptor(onRequestInterceptor);
  onResponseInterceptor && request.useResponseInterceptor(onResponseInterceptor);

  // 子应用自定义的 response interceptor
  request.useResponseInterceptor((res: any, options: any) => {
    const {
      data: { code, errno },
    } = res;
    const { url } = options;

    const tempCode = code ?? errno ?? 0;
    if (~whiteUrlList.indexOf(url)) {
      return res;
    } else if (!successCodeList.includes(tempCode)) {
      return Promise.reject(res);
    }
    return res;
  });
};

// request.useRequestInterceptor(ravenInstance?.retryRequestInterceptor);

// request.useResponseInterceptor(ravenInstance?.retryResponseInterceptor?.(request.request));

/**
 *
 * @param {string} url ： 请求url
 * @param {object} params ： 请求数据
 * @param {object} options : 请求配置
 */
export async function get(url: string, params?: object, options?: any) {
  init();
  return request.get(url, params, options);
}

/**
 *
 * @param {string} url ： 请求url
 * @param {object} params ： 请求数据
 * @param {object} options : 请求配置
 */
const newRequest = new Request({
  baseUrl: BASE_URL,
  externalConfig: {
    useDefaultErrorHandler: false,
  },
  options: {
    timeout: 60000,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  },
});
export async function getJson(url: string, params?: object, options?: any) {
  return newRequest.getJson(url, params, options);
}

/**
 *
 * @param {string} url ： 请求url
 * @param {object} params ： 请求数据
 * @param {object} options : 请求配置
 */
export async function post(url: string, data?: object, options?: any) {
  init();
  return request.post(url, data, options);
}

/**
 *
 * @param {object} config : apollo 配置
 * @param {object} options : 请求配置
 * @returns
 */
export const apolloRequestService = async (config: any, options?: any) => {
  init();
  return request.apolloRequestService(config, options);
};

const godzillaRequest = new Request({
  // baseUrl: 'https://hyworkserver.xiaojukeji.com/api', // 'http://localhost:8080/api' ||
  // baseUrl: 'http://10.96.88.246:8088/api',
  // baseUrl: 'http://10.162.237.234:8088/api',
  baseUrl: 'https://hyversion.xiaojukeji.com/api',
  externalConfig: {
    useDefaultErrorHandler: false,
  },
  options: {
    timeout: 60000,
  },
});

export async function getManifest(url: string, params?: object, options?: any) {
  return godzillaRequest.post(url, params, options);
}
