
import ravenInstance from '@/service/omega/tianyan';
import ravenBlankObserver from '@/service/omega/blankObserver';
import { TRACK_APP_LIFE, TRACK_TIME_APP_MOUNT } from '@/constant';

const endTrackMap = {}; // ITimeEndHandler
const blankObserverMap = {}; // 白屏回调，用户提前结束，适应频繁切换路由时的场景
export const lifeCycle = {
  beforeLoad: [
    (app: any): any => {
      ravenInstance?.raven?.trackEvent(
        `${app?.name}${TRACK_APP_LIFE}`,
        { type: 'info', time: Date.now(), life: 'beforeLoad' },
        '子应用beforeLoad');
      // fenghuo?.trackMultiDomBlank?.(window.document.getElementById(app?.name));
      // 路由切换后则监控子应用白屏情况
      blankObserverMap[app?.name] = ravenBlankObserver(ravenInstance?.raven, {
        domElem: app?.name,
        waitingTime: 6000
      });

      // 计时时间名称
      const trackTimeName = `${app?.name}${TRACK_TIME_APP_MOUNT}`;
      // 开始计时
      endTrackMap[trackTimeName] = ravenInstance?.raven?.trackTime(trackTimeName);
    },
  ],
  beforeMount: [
    (app: any): any => {
      console.log('before mount', app);
      ravenInstance?.raven?.trackEvent(
        `${app?.name}${TRACK_APP_LIFE}`,
        { type: 'info', time: Date.now(), life: 'beforeMount' },
        '子应用beforeMount');
    },
  ],
  afterMount: [
    (app: any): any => {
      console.log('after mount', app);
      blankObserverMap[app?.name]?.cancelReport(); // 切换路由后子应用卸载，之前监测白屏的方法停止
      ravenInstance?.raven?.trackEvent(
        `${app?.name}${TRACK_APP_LIFE}`,
        { type: 'info', time: Date.now(), life: 'afterMount' },
        '子应用afterMount');

      // 计时时间名称
      const trackTimeName = `${app?.name}${TRACK_TIME_APP_MOUNT}`;
      // 结束计时
      endTrackMap[trackTimeName] && endTrackMap[trackTimeName](true, trackTimeName);
    },
  ],
  beforeUnmount: [
    (app: any): any => {
      console.log('before unmount', app);
      blankObserverMap[app?.name]?.stopObserve(); // 切换路由后子应用卸载，之前监测白屏的方法停止
      ravenInstance?.raven?.trackEvent(
        `${app?.name}${TRACK_APP_LIFE}`,
        { type: 'info', time: Date.now(), life: 'beforeUnmount' },
        '子应用beforeUnmount'
      );
    },
  ],
  afterUnmount: [
    (app: any): any => {
      console.log('after unmount', app);
      ravenInstance?.raven?.trackEvent(
        `${app?.name}${TRACK_APP_LIFE}`,
        { type: 'info', time: Date.now(), life: 'afterUnmount' },
        '子应用afterUnmount'
      );
    },
  ],
}