/**
 * 匹配当前页面地址属于哪个子应用
 * @param apps 子应用集合
 * @param location 当前页面的地址
 */
export function matchActiveApp(apps: any[], location: Location) {
  const appsExcludeMain = apps.filter(app => app.name !== 'main');

  const matched = appsExcludeMain.find(app => {
    return app.pathname ? location.pathname === app.pathname : location.pathname.startsWith(`/${app.name}/`);
  });
  if (matched) {
    return matched.name;
  }

  return 'main';
}

/**
 * 创建子应用的命中函数
 */
export const createActiveRule = (apps: any[], appName: string) => {
  return (location: Location) => {
    return matchActiveApp(apps, location) === appName;
  };
};

const MainAppScriptUMD = `
(function (global, factory) {
	typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
	typeof define === 'function' && define.amd ? define(['exports'], factory) :
	(global = typeof globalThis !== 'undefined' ? globalThis : global || self, factory(global.myBundle = {}));
}(this, (function (exports) { 'use strict';

	async function bootstrap() {
		console.log('main app bootstraped');
	}

	async function mount() {
		console.log('main app mounted');
	}

	async function unmount() {
		console.log('main app unmounted');
	}

	exports.bootstrap = bootstrap;
	exports.mount = mount;
	exports.unmount = unmount;

	Object.defineProperty(exports, '__esModule', { value: true });

})));
`;

export const MainAppScriptURL = URL.createObjectURL(new Blob([MainAppScriptUMD], { type: 'application/javascript' }));

export const cdnMap = {
  waterMark: 'https://s3-cxyx-cdn.didistatic.com/fedas-static-test/purchase-fe-statics-cdn-file/water-mark1.0.js',
};

export const asyncAppendScriptElement = (parent: HTMLElement, src: string, callback) => {
  const element = document.createElement('script');
  element.setAttribute('crossorigin', 'anonymous');
  element.setAttribute('referrerpolicy', 'no-referrer');
  element.setAttribute('src', src);
  // @ts-ignore
  element.onload = element.onreadystatechange = function () {
    if (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete') {
      callback && callback();
      // @ts-ignore
      element.onload = element.onreadystatechange = null;
    }
  };
  parent.appendChild(element);
};
