import { ORIGIN_URL, isSim, isLocal } from '@/helper/env';
import { ApiCacheService } from '@/service/api-cache-service';
import { getJson } from '@/service/request';
import { getSessionStorage } from '@/helper/storage';
import { FE_ENV_BRANCH } from '@/constant/storage';
import qs from 'querystring';
import { isProxyMode } from '@/constant/frame-work-proxy-key';
// import userStore from '@/store/user';

/**
 * 获取本地配置
 */
const LocalApplicationRequestService = new ApiCacheService(
  () => {
    return getJson(`//${window.location.host}/config.json?timestamp=${Date.now()}`).then((data: any) => {
      if (!data?.apps) {
        throw new Error('无效的配置');
      }
      return data;
    });
  },
  () => 'AlwaysHitCache'
);

/**
 * 后续菜单都走接口，app的信息走本地
 */
export function fetchApplicationsAndMenus() {
  return LocalApplicationRequestService.fetch()
}

// 获取子应用配置，本地开发，获取本地运行的子应用
export async function fetchApplications() {
  const { apps, menus } = await fetchApplicationsAndMenus()
  const { branch } = qs.parse(location.search.slice(1));
  const branchInSession = getSessionStorage(FE_ENV_BRANCH) || 'master';
  const branchName = branch || branchInSession
  return {
    appsEntryManifests: (apps || []).map(app => {
      let { manifest } = app;
      if (!isProxyMode && (isSim || isLocal)) {
        manifest = manifest.replace(/entry-manifest/, `${branchName}/entry-manifest`);
      }
      return {
        ...app,
        manifest: enhanceResourcePath(app.name, manifest),
        branchName
      }
    }),
    localMenus: menus
  }
}

export function enhanceResourcePath(appName: string, URL: string) {
  return ['//', 'http://', 'https://'].some(prefix => URL.startsWith(prefix)) ? URL : `${ORIGIN_URL}${URL}`;
}
