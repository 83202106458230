import { getJson, getManifest } from '../../request';
import { trackError } from '@/service/omega/tianyan';
import { TRACK_MANIFEST_SOURCE_ERROR_NAME } from '@/constant';
import { APP_ENV, isProdOrPre } from '@/helper/env';
import { isProxyMode } from '@/constant/frame-work-proxy-key';

export const fetchOdinAppManifest = ({ cache = false, ...app }) => {
  const timestamp = Date.now();
  return getJson(
    `${app.manifest}${cache ? '' : `?timestamp=${timestamp}`}`,
    {},
    {
      withCredentials: false,
      needMozId: false,
    }
  )
    .then(data => {
      if (data) {
        return {
          app,
          config: data,
        };
      }
    })
    .catch(e => {
      console.error('get app.manifest err,  app.manifest=>', app.manifest);
      trackError(TRACK_MANIFEST_SOURCE_ERROR_NAME, '请求子应用manifest catch error', {
        error: JSON.stringify(e),
        manifest: app.manifest,
        error_level: 'P0',
        error_type: '0',
      });
    });
};

export const fetchGodzillaAppManifest = appList => {
  const appNameList = appList.map(item => item.projectName);
  const params = {
    nameList: appNameList,
    type: APP_ENV,
  };
  // @ts-ignore
  if (!isProdOrPre && !isProxyMode && appList.length) params.branch = appList[0].branchName;
  return getManifest(`/godzilla/resource`, params)
    .then(res => {
      if (Array.isArray(res.data)) {
        return res.data
          .filter(item => !!item.manifest)
          .map(config => ({
            app: appList.find(item => item.projectName === config.name),
            config: config.manifest
          }));
      }
    })
    .catch(e => {
      console.error('get app.manifest err,  app.manifest=>', appNameList);
      trackError(TRACK_MANIFEST_SOURCE_ERROR_NAME, '请求子应用manifest catch error', {
        error: JSON.stringify(e),
        manifest: appNameList.join(','),
        error_level: 'P0',
        error_type: '0',
      });
    });
};
