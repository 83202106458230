import { passportConfig, FREIGHT_GATEWAY_API_URL } from '@/helper/env';
const { roleId, appId, domain: passportDomain } = passportConfig;
const { protocol } = location;
import { PROJECT_STORE_KEY, PROJECT_USER_KEY, BASE_TABS_STATE } from '@/constant/storage'
import { removeSessionStorage, removeStorage } from '@/helper/storage'

const getPassportUrl = (isLogout = false) => {
  const jumpToUrl = encodeURIComponent(isLogout ? `${location.origin}` : location?.href);
  const setCookieUrl = encodeURIComponent(`${protocol}${FREIGHT_GATEWAY_API_URL}/callback/passport/cookies?path=${jumpToUrl}`);
  return `${passportDomain}?role=${roleId}&source=70001&appid=${appId}&redirectUrl=${setCookieUrl}`;
}

export const login = () => {
  window.location.href = getPassportUrl();
};

export const logout = () => {
  [PROJECT_STORE_KEY, PROJECT_USER_KEY].forEach(key => removeStorage(key))
  removeSessionStorage(BASE_TABS_STATE);
  window.location.href = `${getPassportUrl(true)}#/logout`;
};


