import tianyan from '@/service/omega/tianyan';
// import { TRACK_MANIFEST_SOURCE_ERROR_NAME, TRACK_APP_SOURCE_ERROR_NAME } from '@/constant';
import qs from 'qs';
import _ from 'lodash';
import { setSessionStorage } from '@/helper/storage';
import { FE_ENV_BRANCH } from '@/constant/storage';
import { shouldAddProxyPrefix } from '@/constant/frame-work-proxy-key';
import { HOME_PAGE_PATH, HOME_PAGE_TITLE } from '@/constant/index';
import moment from 'moment';
// 将所有非法数据都转为数组
export const toArray = val => (Array.isArray(val) ? val : []);

// 获取树状结构最大深度
export const getMaxDepth = root => {
  if (!root) return 0;

  let stack = [];
  let depth = 1;

  if (Array.isArray(root)) {
    root?.forEach(item => stack.push(item));
  } else {
    stack = [root];
  }

  while (stack.length) {
    const len = stack.length;

    for (let i = 0; i < len; i++) {
      const node = stack.shift();

      if (!toArray(node.children).length) {
        continue;
      } else {
        node.children.forEach(childNode => stack.push(childNode));
      }
    }
    depth++;
  }
  return --depth;
};

// 返回指定层级的数据
export const getSpecialDepthData = (data, depth, maxDepth) => {
  depth = depth >= maxDepth ? maxDepth : depth;
  depth--;
  return data?.map(item => {
    if (item?.children && depth > 0) {
      getSpecialDepthData(item.children, depth, maxDepth);
    } else {
      delete item.children;
    }
    return item;
  });
};

export const getHashQueryString = (name: string) => {
  const w = location.hash.indexOf('?');
  const query = location.hash.substring(w + 1);

  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] == name) {
      return pair[1];
    }
  }

  return false;
};

export const getSearchQueryString = variable => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
};

export function debounce(fn, wait) {
  let timer = null;
  return function (...args) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, wait);
  };
}

export function getMouseLeaveDirection(e) {
  const _this = e.target.getBoundingClientRect();
  const w = _this.width;
  const h = _this.height;
  const offsetLeft = _this.left;
  const offsetTop = _this.top;
  const x = (e.pageX - offsetLeft - w / 2) * (w > h ? h / w : 1);
  const y = (e.pageY - offsetTop - h / 2) * (h > w ? w / h : 1);
  const direction = Math.round((Math.atan2(y, x) * (180 / Math.PI) + 180) / 90 + 3) % 4;
  return direction;
}

// 线下环境初始化分支参数
export const initBranchInSession = () => {
  const { branch = 'master' } = qs.parse(location.search.slice(1));
  setSessionStorage(FE_ENV_BRANCH, branch);
};

// 个位数补0
export const fillZero = num => {
  return num < 10 ? '0' + num : num;
};

// 获取当前年月日，如 20211215
export const getYYYYMMDD = () => {
  const dateObj = new Date();
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const date = dateObj.getDate();
  return `${year}${fillZero(month)}${fillZero(date)}`;
};

// 延迟函数
export const delay = (time = 300) => new Promise(resolve => setTimeout(resolve, time));

// 转换成 string 类型的 json
export const stringifyObj = obj => {
  try {
    return JSON.stringify(obj) || '{}';
  } catch (error) {
    return '{}';
  }
};

// 获取当前子应用name
export const getCurrentMicroName = () => window.location.pathname.replace(/\//g, '');

export const firstInteractiveDurationStart = () => {
  // firstInteractiveDuration 开始计时
  (window as any).ravenFirstInterCallback = tianyan?.raven?.trackTime('first_interactive_duration');
};

export const firstInteractiveDurationSend = () => {
  // 基于目前所有子应用cache-route命名规范都为 `${appName}-route-content`
  const targetNode = document.getElementById(`${location?.pathname?.split('/')?.[1]}`);
  if (!targetNode) {
    return;
  }
  (window as any).observe = new MutationObserver(() => {
    const routeContent = document.getElementById(
      `${location?.pathname?.split('/')?.[1]}-route-content`
    );
    if ((window as any)?.ravenFirstInterCallback && routeContent) {
      (window as any)?.ravenFirstInterCallback();
      (window as any).observe.disconnect();
      delete (window as any).observe;
      delete (window as any)?.ravenFirstInterCallback;
    }
  });
  (window as any).observe.observe(targetNode, { childList: true, subtree: true, attributes: true });
};

/**
 * @description 格式化时间
 * @param time
 * @param formatStr
 * @returns
 */
export const formatTime = (time, formatStr = 'YYYY-MM-DD HH:mm:ss') => {
  if (!time) return '--';
  return moment(time).format(formatStr);
};

export const get30DaysTime = () => {
  return {
    startTime: moment().startOf('day').subtract(30, 'd').format('YYYY-MM-DD HH:mm:ss'),
    endTime: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  };
};

/**
 * 下载文件
 * @param {string} fileUrl
 */
export function downloadFile(fileUrl, fileName = '数据') {
  const eleLink = document.createElement('a');
  eleLink.download = fileName;
  eleLink.style.display = 'none';
  eleLink.href = fileUrl;
  eleLink.click();
}

function getMenuMap(menuList) {
  return menuList.reduce(
    (result, app) => {
      if (!app?.children?.length) {
        result[app.path] = {
          name: app.name,
          nameCN: app.nameCN,
          path: app.path,
        };
      } else {
        app.children.forEach(menu => {
          result[shouldAddProxyPrefix(menu.path)] = {
            name: menu.name,
            nameCN: menu.nameCN,
            path: menu.path,
          };
        });
      }
      return result;
    },
    {
      [HOME_PAGE_PATH]: {
        name: HOME_PAGE_TITLE,
        nameCN: HOME_PAGE_TITLE,
        path: HOME_PAGE_PATH,
      },
    }
  );
}
const memizedGetMenuMap = _.memoize(getMenuMap); 
// 通过路由获取菜单信息
export const getMenuInfo = (menuList, path) => {
  const menuMap = memizedGetMenuMap(menuList);
  return menuMap[path];
}
