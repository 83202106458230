import { createContext, Dispatch, Reducer } from 'react';
import { message } from 'antd';
import { loadApp } from '@/service/qiankun/index';
import { Route, Tab } from '@/components/tab/Tabs';
import formatLocationPath from '@/utils/formatLocationPath';
import { setSessionStorage } from '@/helper/storage';
import { BASE_TABS_STATE } from '@/constant/storage';
import userStore from '@/store/user';

import { isProxyMode, PROXY_PREFIX, shouldAddProxyPrefix } from '@/constant/frame-work-proxy-key';
// import { TRACK_TAB_STATE_CHANGE } from '@/constant';

export declare type NormalizationMenu = {
  name?: string;
  nameCN?: string;
  path: string;
  [key: string]: any;
}

export declare type EntryManifestItem = {
  name: string;
  scripts: string[];
  style: string[];
}

export declare interface TabContextState {
  loading?: boolean;
  menuMap?: Record<string, NormalizationMenu>;
  appsEntryManifestMap?: Record<string, EntryManifestItem>;
  tabsMap?: Record<string, Tab>;
  tabsList?: Tab[];
  currentTab?: Tab;
  wrapperMap?: Record<string, HTMLDivElement>;
  microAppMap?: any;
  handlePopState?: (e: PopStateEvent) => void;
  microBreadCrumbNameMap?: Record<string, string>
}

export declare interface TabContextAction {
  type: 'LOADING'
  | 'LOADED'
  | 'INIT_STATE'
  | 'CLICK_MENU'
  | 'CLICK_TAB'
  | 'CLICK_BREADCRUMB'
  | 'CLICK_GO_BACK'
  | 'PUSH_ROUTE'
  | 'CLOSE'
  | 'CLOSE_OTHER_ALL'
  | 'CLOSE_ALL'
  | 'REFRESH'
  | 'JUMP_TO_TAB'
  | 'BROWSER_BACK_OR_FORWARD'
  | 'SET_MICRO_BREAD_CRUMB_NAME_MAP'
  | 'REPLACE_BREADCRUMB'
  | 'POPSTATE_BREADCRUMB'
  | 'CLICK_MENU_PROMISE';
  value?: TabActionValue | PathActionValue | RouteActionValue | TabContextState | NoOtherParams | MicroBreadCrumbNameMapActionValue;
}

export declare interface BaseTabsState {
  tabsMap?: Record<string, Tab>;
  tabsList?: Tab[];
  currentTab?: Tab;
}

export declare type TabActionValue = {
  tab: Tab;
  wrapper?: any;
}

export declare type PathActionValue = {
  path: string;
  wrapper?: any;
}

export declare type RouteActionValue = {
  route: Route;
  wrapper?: any;
  currentAppName?: string;
}

export declare type MicroBreadCrumbNameMapActionValue = {
  microBreadCrumbNameMap: Record<string, string>
}

export declare type NoOtherParams = {
  wrapper?: any;
}

export const TabsContext = createContext<{ state: TabContextState; dispatch: Dispatch<TabContextAction> }>({
  state: {},
  dispatch: () => ({}),
})

function parseUri(str: string) {
  const anchor = document.createElement("a");
  anchor.href = str;
  return anchor;
}

const dropRoutes = (microAppMap, routes) => {
  setTimeout(async () => {
    for (let index = 0; index < routes?.length; index++) {
      const dropRoute = routes[index]?.route || routes[index]?.path?.split('#')[1]
      await microAppMap[getMicroAppName(routes[index]?.path)]?.update?.({ value: dropRoute });
    }
  });
}

export const checkUrlAndRouteIsSame = (route: string, rulePath: string) => {
  if (route?.includes(':')) {
    const shiftyRouteArr = route?.split('/:');
    const noMustRouteNum = shiftyRouteArr.filter(item => item.includes('?'))?.length;
    const totalRouteNum = shiftyRouteArr.length - 1;
    const noParams = shiftyRouteArr[0];
    const ruleRouteNum = rulePath?.slice(noParams.length).split('')?.filter(item => item === '/')?.length;
    return noParams === rulePath?.slice(0, noParams.length)
      && (!rulePath[noParams.length] || rulePath[noParams.length] === '/')
      && (ruleRouteNum >= totalRouteNum - noMustRouteNum && ruleRouteNum <= totalRouteNum);
  } else if (rulePath?.includes('?')) {
    return route === rulePath?.split('?')[0];
  } else {
    return route === rulePath;
  }
}

export const setBreadName = (routes: any) => {
  const breadPath = routes?.path?.split('#')[1] || '';
  const currentData = window.MICRO_ALL_ROUTES?.find(item => {
    return checkUrlAndRouteIsSame(item?.path, breadPath)
  })
  if (routes?.name && routes?.name !== '未知详情页' && routes?.name !== '未命名') {
    return { ...routes, route: currentData?.path }
  }
  return { ...routes, name: currentData?.name || '未知详情页', route: currentData?.path }
}


export const pushStateWithDropCache = (path: string, state?: {}) => {
  let microName = '';
  let realPath = '';
  let jumpPath = '';
  if (path?.includes('#')) {
    microName = getMicroAppName(path);
    realPath = path?.split('#')[1] || '';
    jumpPath = path;
  } else {
    microName = window?.BASE_TABS_STATE?.currentTab?.appName || '';
    realPath = path || '';
    jumpPath = `/${microName}/#${path}`
  }

  const currentData = window?.MICRO_ALL_ROUTES?.find(item => {
    return checkUrlAndRouteIsSame(item?.path, realPath)
  });

  if (window?.BASE_TABS_STATE?.microAppMap?.[microName]?.update) {
    window?.BASE_TABS_STATE
      ?.microAppMap?.[microName]
      ?.update?.({ value: currentData?.path }).then(() => {
        window.history.pushState({
          ...state,
        }, '', formatLocationPath(jumpPath));
      })
  } else {
    window.history.pushState({
      ...state,
    }, '', formatLocationPath(jumpPath));
  }
}

export const replaceStateWithDropCache = (path: string, state?: {}) => {
  let microName = '';
  let realPath = '';
  let jumpPath = '';
  if (path?.includes('#')) {
    microName = getMicroAppName(path);
    realPath = path?.split('#')[1] || '';
    jumpPath = path;
  } else {
    microName = window?.BASE_TABS_STATE?.currentTab?.appName || '';
    realPath = path || '';
    jumpPath = `/${microName}/#${path}`
  }

  const currentData = window?.MICRO_ALL_ROUTES?.find(item => {
    return checkUrlAndRouteIsSame(item?.path, realPath)
  });

  if (window?.BASE_TABS_STATE?.microAppMap?.[microName]?.update) {
    window?.BASE_TABS_STATE
      ?.microAppMap?.[microName]
      ?.update?.({ value: currentData?.path }).then(() => {
        window.history.replaceState({
          REPLACE_STATE: true,
          ...state,
        }, '', formatLocationPath(jumpPath));
      })
  } else {
    window.history.replaceState({
      REPLACE_STATE: true,
      ...state,
    }, '', formatLocationPath(jumpPath));
  }
}

export const getMicroAppName = (path: string) => {
  const pathname = parseUri(path).pathname;
  if (isProxyMode && path.startsWith(PROXY_PREFIX)) return pathname.slice(PROXY_PREFIX.length + 1, pathname.length - 1);
  return pathname.slice(1, pathname.length - 1);
}

function addTab(route: Route, tabsList: Tab[], tabsMap: Record<string, Tab>) {
  const tab = new Tab({
    name: route.name || setBreadName(route)?.name,
    path: route.path
  })
  tabsList.push(tab);
  tabsMap[shouldAddProxyPrefix(route.path)] = tab;
  tab.activeTab();
  return tab;
}

function changeTab({
  currentAppName,
  newAppName,
  route,
  wrapperMap,
  handlePopState,
}: {
  currentAppName: string;
  newAppName: string;
  route: Route;
  wrapperMap: Record<string, HTMLDivElement>;
  handlePopState: (e: PopStateEvent) => void;
}) {
  if (!route) console.error('change tab error');
  if (currentAppName === newAppName) {
    processListener(switchHash, handlePopState)(route);
  } else {
    processListener(switchApp, handlePopState)(route, currentAppName, newAppName, wrapperMap);
  }
}

function switchHash(route: Route) {
  return new Promise((resolve) => {
    window.history.pushState({}, route.name, formatLocationPath(route.path));
    const hashChangeEvent = new HashChangeEvent('hashchange');
    window.dispatchEvent(hashChangeEvent);
    resolve(null);
  })
}

function processDomDisplay(currentAppName: string, newAppName: string, wrapperMap: Record<string, HTMLDivElement>) {
  wrapperMap[currentAppName] && (wrapperMap[currentAppName].style.display = 'none');
  wrapperMap[newAppName].style.display = 'block';
}

function switchApp(route: Route, currentAppName: string, newAppName: string, wrapperMap: Record<string, HTMLDivElement>) {
  return new Promise((resolve) => {
    processDomDisplay(currentAppName, newAppName, wrapperMap);
    resolve(null);
  })
    .then(() => {
      window.history.pushState({}, route.name, formatLocationPath(route.path));
      const hashChangeEvent = new HashChangeEvent('hashchange');
      window.dispatchEvent(hashChangeEvent);
    });
}

function processAddEventListener() {
  Promise.resolve()
    .then(() => {
      window.removeEventListener('popstate', processAddEventListener);
    })
    .then(() => {
      window.history.replaceState({
        ...window.history.state,
        CURRENT_ROUTE_TABS_INFO: window.BASE_TABS_CURRENT_TAB
      }, '')
    })
    .then(() => {
      window.addEventListener('popstate', window.BASE_HANDLE_POP_STATE);
    });
}

function processListener(switchFunc: typeof switchApp | typeof switchHash, handlePopState: (e: PopStateEvent) => void) {
  return (...args: Parameters<typeof switchFunc>) => {

    Promise.resolve()
      .then(() => {
        window.removeEventListener('popstate', handlePopState);
      })
      .then(() => {
        window.addEventListener('popstate', processAddEventListener);
      })
      .then(async () => {
        //@ts-ignore
        await switchFunc(...args);
      });
  }
}

export function persistedTabsState(state: TabContextState): TabContextState {

  window.BASE_TABS_STATE = state;
  const {
    tabsMap,
    tabsList,
    currentTab,
  } = state

  setSessionStorage(BASE_TABS_STATE, {
    tabsMap,
    tabsList,
    currentTab,
  });

  return state;
}

export const reducer: Reducer<TabContextState, TabContextAction> = (state, action) => {
  const { type, value } = action;
  // tianyan?.raven?.trackEvent(TRACK_TAB_STATE_CHANGE, {
  //   type,
  //   value,
  //   oldState: state
  // }, 'tab-state-change');
  switch (type) {
    case 'LOADING': {
      return Object.assign({}, state, { loading: true });
    }
    case 'LOADED': {
      return Object.assign({}, state, { loading: false });
    }
    case 'INIT_STATE': {
      window.addEventListener('popstate', (value as TabContextState).handlePopState);
      window.BASE_HANDLE_POP_STATE = (value as TabContextState).handlePopState;
      return persistedTabsState(Object.assign({}, state, value));
    }
    case 'CLICK_MENU': {
      const {
        route,
        // wrapper
      } = value as RouteActionValue;
      if (route?.path) route.path = shouldAddProxyPrefix(route.path)
      let { currentTab } = state;
      const {
        tabsList,
        tabsMap,
      } = state;

      if (tabsMap[route.path]) {
        currentTab.inactiveTab();
        tabsMap[route.path].activeTab();
        currentTab = tabsMap[route.path];
      } else {
        currentTab.inactiveTab();
        currentTab = addTab(route, tabsList, tabsMap);
      }

      return persistedTabsState(Object.assign({}, state, {
        currentTab,
        tabsList,
        tabsMap,
      }));
    }
    case 'CLICK_MENU_PROMISE': {
      const {
        route,
        wrapper,
        currentAppName
      } = value as RouteActionValue;
      if (route?.path) route.path = shouldAddProxyPrefix(route.path)
      const { currentTab } = state;
      const {
        wrapperMap,
        microAppMap,
        handlePopState,
      } = state;

      const newAppName = getMicroAppName(route.path);

      if (!(newAppName in wrapperMap) && wrapper) {
        wrapperMap[newAppName] = wrapper?.currentAppWrapper;
        microAppMap[newAppName] = wrapper?.microApp;
      }

      changeTab({
        currentAppName,
        newAppName,
        route: currentTab.activeRoute,
        wrapperMap,
        handlePopState
      });
      return persistedTabsState(Object.assign({}, state, {
        wrapperMap,
        microAppMap
      }));
    }
    case 'CLICK_TAB': {
      const {
        tab,
        wrapper
      } = value as TabActionValue;

      let { currentTab } = state;
      const {
        wrapperMap,
        microAppMap,
        tabsMap,
        handlePopState,
      } = state;

      const currentAppName = getMicroAppName(currentTab.activeRoute.path);
      const newAppName = getMicroAppName(tab.activeRoute.path);

      if (!(newAppName in wrapperMap)) {
        wrapperMap[newAppName] = wrapper?.currentAppWrapper;
        microAppMap[newAppName] = wrapper?.microApp;
      }

      currentTab.inactiveTab();
      tabsMap[tab.baseRoute.path].activeTab();
      currentTab = tabsMap[tab.baseRoute.path];
      changeTab({
        currentAppName,
        newAppName,
        route: tab.activeRoute,
        wrapperMap,
        handlePopState
      });

      return persistedTabsState(Object.assign({}, state, {
        wrapperMap,
        microAppMap,
        currentTab,
        tabsMap,
      }));
    }
    case 'CLICK_BREADCRUMB': {
      const {
        path,
        wrapper
      } = value as PathActionValue;
      const {
        currentTab,
        wrapperMap,
        microAppMap,
        handlePopState
      } = state;
      const currentAppName = getMicroAppName(currentTab.activeRoute.path);
      const newAppName = getMicroAppName(path)

      if (!(newAppName in wrapperMap)) {
        wrapperMap[newAppName] = wrapper?.currentAppWrapper;
        microAppMap[newAppName] = wrapper?.microApp;
      }

      const route = currentTab.findRoute(path)

      if (route) {
        changeTab({
          currentAppName,
          newAppName,
          route,
          wrapperMap,
          handlePopState
        });

        currentTab.jumpToRoute(path);

        dropRoutes(microAppMap, currentTab.dropRoute);
      }

      return persistedTabsState(Object.assign({}, state, {
        wrapperMap,
        microAppMap,
        currentTab,
      }));
    }
    case 'POPSTATE_BREADCRUMB': {
      const {
        path,
        wrapper
      } = value as PathActionValue;
      const {
        currentTab,
        wrapperMap,
        microAppMap,
        // handlePopState
      } = state;
      const currentAppName = getMicroAppName(currentTab.activeRoute.path);
      const newAppName = getMicroAppName(path)

      if (!(newAppName in wrapperMap)) {
        wrapperMap[newAppName] = wrapper?.currentAppWrapper;
        microAppMap[newAppName] = wrapper?.microApp;
      }

      if (currentAppName !== newAppName) {
        processDomDisplay(currentAppName, newAppName, wrapperMap)
      }

      currentTab.jumpToRoute(path);
      dropRoutes(microAppMap, currentTab.dropRoute);

      const hashChangeEvent = new HashChangeEvent('hashchange');
      window.dispatchEvent(hashChangeEvent);

      window.history.replaceState({
        ...window.history.state,
        CURRENT_ROUTE_TABS_INFO: window.BASE_TABS_CURRENT_TAB
      }, '')

      return persistedTabsState(Object.assign({}, state, {
        wrapperMap,
        microAppMap,
        currentTab,
      }));
    }
    case 'JUMP_TO_TAB': {
      const {
        route,
        wrapper
      } = value as RouteActionValue;
      if (route?.path) route.path = shouldAddProxyPrefix(route.path)
      let { currentTab } = state;
      const {
        wrapperMap,
        microAppMap,
        tabsList,
        tabsMap,
      } = state;

      const currentAppName = getMicroAppName(currentTab.activeRoute.path);
      const newAppName = getMicroAppName(route.path);

      if (!(newAppName in wrapperMap)) {
        wrapperMap[newAppName] = wrapper?.currentAppWrapper;
        microAppMap[newAppName] = wrapper?.microApp;
      }

      if (currentAppName !== newAppName) {
        processDomDisplay(currentAppName, newAppName, wrapperMap)
      }

      if (tabsMap[route.path]) {
        currentTab.inactiveTab();
        tabsMap[route.path].activeTab();
        currentTab = tabsMap[route.path];
        currentTab.jumpToRoute(route.path);
        dropRoutes(microAppMap, currentTab.dropRoute);
      } else {
        currentTab.inactiveTab();
        currentTab = addTab(route, tabsList, tabsMap);
      }

      const hashChangeEvent = new HashChangeEvent('hashchange');
      window.dispatchEvent(hashChangeEvent);

      window.history.replaceState({
        ...window.history.state,
        CURRENT_ROUTE_TABS_INFO: window.BASE_TABS_CURRENT_TAB
      }, '')

      return persistedTabsState(Object.assign({}, state, {
        wrapperMap,
        microAppMap,
        currentTab,
        tabsList,
        tabsMap,
      }));
    }
    case 'CLICK_GO_BACK': {
      const { wrapper } = value as NoOtherParams;
      const {
        currentTab,
        wrapperMap,
        microAppMap,
        handlePopState
      } = state;

      const currentAppName = getMicroAppName(currentTab.activeRoute.path);
      const newAppName = getMicroAppName(currentTab.routes[currentTab.routes.length - 2].path);

      if (!(newAppName in wrapperMap)) {
        wrapperMap[newAppName] = wrapper?.currentAppWrapper;
        microAppMap[newAppName] = wrapper?.microApp;
      }

      changeTab({
        currentAppName,
        newAppName,
        route: currentTab.routes[currentTab.routes.length - 2],
        wrapperMap,
        handlePopState
      });

      currentTab.jumpToRoute(currentTab.routes[currentTab.routes.length - 2].path);
      dropRoutes(microAppMap, currentTab.dropRoute);

      return persistedTabsState(Object.assign({}, state, {
        wrapperMap,
        microAppMap,
        currentTab,
      }))
    }
    case 'PUSH_ROUTE': {
      const {
        route,
        wrapper
      } = value as RouteActionValue;
      const {
        currentTab,
        wrapperMap,
        microAppMap,
      } = state;
      const currentData = window.MICRO_ALL_ROUTES?.find(item => {
        return checkUrlAndRouteIsSame(item?.path, route?.path?.split('#')[1])
      })

      if (!currentData) {
        message.warning('您访问的页面不存在')
        window.history.replaceState({}, currentTab?.activeRoute?.name, formatLocationPath(currentTab?.activeRoute?.path));
      } else {
        const currentAppName = getMicroAppName(currentTab.activeRoute.path);
        const newAppName = getMicroAppName(route.path);

        if (!(newAppName in wrapperMap)) {
          wrapperMap[newAppName] = wrapper?.currentAppWrapper;
          microAppMap[newAppName] = wrapper?.microApp;
        }

        currentTab.addRoute(route);
        dropRoutes(microAppMap, currentTab.dropRoute);

        if (currentAppName !== newAppName) {
          processDomDisplay(currentAppName, newAppName, wrapperMap)
        }
      }

      window.dispatchEvent(new HashChangeEvent('hashchange'));
      window.history.replaceState({
        ...window.history.state,
        CURRENT_ROUTE_TABS_INFO: window.BASE_TABS_CURRENT_TAB
      }, '')

      return persistedTabsState(Object.assign({}, state, {
        wrapperMap,
        microAppMap,
        currentTab,
      }));
    }
    case 'CLOSE': {
      const {
        tab,
        wrapper
      } = value as TabActionValue;
      const {
        tabsMap,
        wrapperMap,
        microAppMap,
        handlePopState
      } = state;
      let {
        currentTab,
        tabsList = []
      } = state;
      const needDeletedTabIndex = tabsList.findIndex(item => item === tab);
      if (needDeletedTabIndex <= 0) {
        return state
      }

      const currentAppName = getMicroAppName(currentTab.activeRoute.path);

      delete tabsMap[tabsList[needDeletedTabIndex].baseRoute.path];

      if (tab === currentTab) {
        currentTab = tabsList[needDeletedTabIndex - 1]
        currentTab.activeTab();

        const newAppName = getMicroAppName(currentTab.activeRoute.path);

        if (!(newAppName in wrapperMap)) {
          // const wrapper = loadApp({
          //   appName: newAppName,
          //   appsEntryManifestMap: state.appsEntryManifestMap
          // });
          wrapperMap[newAppName] = wrapper?.currentAppWrapper;
          microAppMap[newAppName] = wrapper?.microApp;
        }

        changeTab({
          currentAppName,
          newAppName,
          route: currentTab.activeRoute,
          wrapperMap,
          handlePopState
        });
      }

      tabsList = tabsList.filter((item, index) => index !== needDeletedTabIndex);

      setTimeout(async () => {
        for (let index = 0; index < tab?.routes.length; index++) {
          const dropRoute = tab?.routes[index]?.route || tab?.routes[index]?.path?.split('#')[1]
          await microAppMap[tab?.appName]?.update?.({ value: dropRoute });
        }
      });

      return persistedTabsState(Object.assign({}, state, {
        wrapperMap,
        microAppMap,
        currentTab,
        tabsMap,
        tabsList
      }))
    }
    case 'CLOSE_OTHER_ALL': {
      const {
        tab,
        wrapper
      } = value as TabActionValue;
      let {
        currentTab,
        tabsMap,
        tabsList,
      } = state;
      const {
        microAppMap,
        wrapperMap,
        handlePopState,
      } = state

      const currentAppName = getMicroAppName(currentTab.activeRoute.path);
      for (const key in microAppMap) {
        microAppMap[key]?.update?.({ type: 'clearAll' })
      }

      currentTab.inactiveTab();
      currentTab = tab;
      const newAppName = getMicroAppName(currentTab.activeRoute.path);
      if (tab.baseRoute.path === userStore.HOME_PAGE_PATH) {
        tabsList = tabsList.slice(0, 1);
        tabsMap = {
          [tabsList[0].baseRoute.path]: tabsList[0],
        }
      } else {
        tabsList = tabsList.slice(0, 1).concat(currentTab);
        tabsMap = {
          [tabsList[0].baseRoute.path]: tabsList[0],
          [tabsList[1].baseRoute.path]: tabsList[1],
        }
      }
      if (!(newAppName in wrapperMap)) {
        wrapperMap[newAppName] = wrapper?.currentAppWrapper;
        microAppMap[newAppName] = wrapper?.microApp;
      }

      currentTab.activeTab();

      changeTab({
        currentAppName,
        newAppName,
        route: currentTab.activeRoute,
        wrapperMap,
        handlePopState
      });

      return persistedTabsState(Object.assign({}, state, {
        wrapperMap,
        microAppMap,
        currentTab,
        tabsMap,
        tabsList
      }))
    }
    case 'CLOSE_ALL': {
      const {
        wrapperMap,
        microAppMap,
        handlePopState
      } = state;
      let {
        currentTab,
        tabsMap,
        tabsList
      } = state;

      const currentAppName = getMicroAppName(currentTab.activeRoute.path);

      currentTab = tabsMap[userStore.HOME_PAGE_PATH];
      tabsList = [currentTab];
      tabsMap = {
        [userStore.HOME_PAGE_PATH]: currentTab
      };

      const newAppName = getMicroAppName(currentTab.activeRoute.path);

      if (!(newAppName in wrapperMap)) {
        const wrapper = loadApp({
          appName: newAppName,
          appsEntryManifestMap: state.appsEntryManifestMap
        });
        wrapperMap[newAppName] = wrapper?.currentAppWrapper;
        microAppMap[newAppName] = wrapper?.microApp;
      }

      currentTab.activeTab();

      changeTab({
        currentAppName,
        newAppName,
        route: currentTab.activeRoute,
        wrapperMap,
        handlePopState
      });

      setTimeout(() => {
        for (const key in microAppMap) {
          microAppMap[key]?.update?.({ type: 'clearAll' })
        }
      })

      return persistedTabsState(Object.assign({}, state, {
        currentTab,
        tabsMap,
        tabsList,
        wrapperMap,
        microAppMap,
      }))
    }
    case 'REFRESH': {
      const {
        tab
      } = value as TabActionValue;

      const {
        microAppMap,
        currentTab
      } = state;
      const currentActiveInfo = tab?.routes[tab?.routes?.length - 1]
      const dropRouteKey = currentActiveInfo?.route || currentActiveInfo?.path?.split('#')[1]
      if (!microAppMap[tab?.appName]?.update) return persistedTabsState(Object.assign({}, state));
      if (tab === currentTab) {
        processListener((route: Route) => {
          return new Promise(() => {
            window.history.replaceState({}, route.name, `/${getMicroAppName(route.path)}/${location.search}#/refresh`);
            const hashChangeEvent = new HashChangeEvent('hashchange');
            window.dispatchEvent(hashChangeEvent);
            setTimeout(() => {
              microAppMap[tab?.appName]?.update?.({ value: dropRouteKey }).then(() => {
                window.history.replaceState({}, route.name, formatLocationPath(route?.path));
                const hashChangeEvent = new HashChangeEvent('hashchange');
                window.dispatchEvent(hashChangeEvent);
              })
            })
          })
        }, state.handlePopState)(currentActiveInfo);
      } else {
        microAppMap[tab?.appName]?.update?.({ value: dropRouteKey });
      }
      return persistedTabsState(Object.assign({}, state));
    }
    case 'BROWSER_BACK_OR_FORWARD': {
      const {
        tab,
        wrapper
      } = value as TabActionValue;
      let {
        tabsList,
        currentTab
      } = state;
      const {
        tabsMap,
        wrapperMap,
        microAppMap,
      } = state;
      if (currentTab.baseRoute.path.split('?')[0] === tab.baseRoute.path.split('?')[0]) {
        dropRoutes(microAppMap, currentTab.routes);
      }

      const currentAppName = getMicroAppName(currentTab.activeRoute.path);
      const newAppName = getMicroAppName(tab.activeRoute.path)

      if (tab.baseRoute.path in tabsMap) {
        tabsMap[tab.baseRoute.path] = tab;
        tabsList = tabsList.map(item => {
          if (item.baseRoute.path !== tab.baseRoute.path) return item;
          else return tab;
        })
        currentTab.inactiveTab();
        currentTab = tab;
        currentTab.activeTab();
      } else {
        tabsList.push(tab);
        tabsMap[tab.baseRoute.path] = tab;
        currentTab.inactiveTab();
        currentTab = tab;
        currentTab.activeTab();
      }


      if (currentAppName !== newAppName) {
        if (!(newAppName in wrapperMap)) {
          // const wrapper = loadApp({
          //   appName: newAppName,
          //   appsEntryManifestMap: state.appsEntryManifestMap
          // });
          wrapperMap[newAppName] = wrapper?.currentAppWrapper;
          microAppMap[newAppName] = wrapper?.microApp;
        }
        processDomDisplay(currentAppName, newAppName, wrapperMap);
        const hashChangeEvent = new HashChangeEvent('hashchange');
        window.dispatchEvent(hashChangeEvent);
      }

      return persistedTabsState(Object.assign({}, state, {
        wrapperMap,
        microAppMap,
        tabsList,
        currentTab,
        tabsMap,
      }));
    }
    case "SET_MICRO_BREAD_CRUMB_NAME_MAP": {
      const { microBreadCrumbNameMap } = value as MicroBreadCrumbNameMapActionValue;
      return Object.assign({}, state, {
        microBreadCrumbNameMap
      })
    }
    case 'REPLACE_BREADCRUMB': {
      const {
        route,
        wrapper
      } = value as RouteActionValue;
      const {
        currentTab,
        wrapperMap,
        microAppMap,
      } = state;

      const currentAppName = getMicroAppName(currentTab.activeRoute.path);
      const newAppName = getMicroAppName(route.path)

      if (!(newAppName in wrapperMap)) {
        wrapperMap[newAppName] = wrapper?.currentAppWrapper;
        microAppMap[newAppName] = wrapper?.microApp;
      }

      const replaceRoute = currentTab.routes?.splice(currentTab.routes.length - 1);

      if (currentAppName !== newAppName) {
        processDomDisplay(currentAppName, newAppName, wrapperMap)
      }

      currentTab.addRoute(route);
      dropRoutes(microAppMap, replaceRoute);

      window.dispatchEvent(new HashChangeEvent('hashchange'));
      window.history.replaceState({
        ...window.history.state,
        CURRENT_ROUTE_TABS_INFO: window.BASE_TABS_CURRENT_TAB
      }, '');

      return persistedTabsState(Object.assign({}, state, {
        wrapperMap,
        microAppMap,
        currentTab,
      }));
    }
  }
}
