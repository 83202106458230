import Fenghuo, { FenghuoBlankObserver, FenghuoResourse, FenghuoPageView } from '@didi/fenghuo'
import omegaTracker from '@didi/omega-tracker-lite';
import { isProdOrPre, isSim, isLocal, isPre, isProduction } from '@/helper/env'

interface Attrs {
  [key: string]: any
}
let env: 'production' | 'devlopment' | 'pre' = 'production';
if (isSim || isLocal) {
  env = 'devlopment'
} else if (isPre) {
  env = 'pre'
}


const fenghuo: any = new Fenghuo({
  appId: 7,
  mergeNums: 10,
  notReport: false,
  env,
  openPlayback: isPre
})


// 资源插件
fenghuo?.use(FenghuoBlankObserver, { multiDomMode: true, waitTime: 6000, dom: document.getElementById("root") });
fenghuo?.use(FenghuoPageView, { autoTrack: true })

const Omega = isProduction && omegaTracker.getTracker({
  appKey: 'omegac3c3073c8e',
  autoError: false,
  autoSendPageView: false,
  hashRouterEnable: false,
  browserRouterEnable: false,
  autoPerformance: false,
  autoClick: false,
});

// freight_parter_huoyun_menu_ck  货运通上报埋点

export const omegaTrackEvent = (eventName, attrs) => {
  if (!isProduction) return
  Omega?.trackEvent(eventName, '', { ...(window as any).FREIGHT_OMEGA_CONFIG, ...attrs })
};

export const omegaSetConfig = (config) => {
  if (!isProduction) return
  (window as any).FREIGHT_OMEGA_CONFIG = {
    ...(window as any).FREIGHT_OMEGA_CONFIG,
    ...config
  }
}


/** ==================fenghuo ================= */
export const trackError = (eventName, eventMessage?: string, ext?: Attrs) => {

  fenghuo?.trackError(eventName, eventMessage, ext)
}

export const trackEvent = (eventName, eventMessage?: string, ext?: Attrs) => {
  fenghuo?.trackEvent(eventName, eventMessage, ext)
}

export const onRequestInterceptor = (url, options,) => {
  try {
    const fenghuoConfig: any = {
      st: new Date().valueOf(),
      url
    }
    const curl = url?.includes('action=') ? url?.split('action=')?.[1] : url
    fenghuoConfig.done = fenghuo?.trackRequest(curl, options)
    return {
      url,
      options: {
        fenghuoConfig,
        ...options
      }
    }
  } catch (error) {
    trackError('onRequestInterceptor', error?.message, error)
    return {
      url,
      options
    }
  }

}

export const onResponseInterceptor = (res, rest) => {
  try {
    const { code, subcode, traceId, msg, message } = res?.data || {};
    const { fenghuoConfig } = rest || {};
    const success = subcode == 200 && code == 200
    const str = message || msg;
    let eventName = 'TRACK_REQUEST_NORMAL'
    if (str && subcode != 200 && (!/.*[\u4e00-\u9fa5]+.*$/.test(str) || str?.startsWith('系统'))) {
      eventName = 'TRACK_REQUEST_SUB_CODE_NOT_200'
    }
    fenghuoConfig?.done?.(traceId, success, { ...res?.data }, { eventName });
    return res
  } catch (error) {
    trackError('onResponseInterceptor', error?.message, error)
    return res
  }


}

export const setConfig = (config) => {
  (window as any).Fenghuoconfig = { ...(window as any).Fenghuoconfig, ...config }
  fenghuo?.setConfig(config)
}
export const setAttrs = (attr) => {
  fenghuo?.setAttr(attr)
}
fenghuo.onRequestInterceptor = onRequestInterceptor;
fenghuo.onResponseInterceptor = onResponseInterceptor;

export default fenghuo

