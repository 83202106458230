/**
 * 异步import渲染，等待联邦模块加载
//  */
import { TRACK_ERROR_NAME, TRACK_PAGELOAD_TIME, TRACK_PAGE_VIEW } from '@/constant';
import { setSessionStorage } from '@/helper/storage';
import { getMicroAppName, checkUrlAndRouteIsSame } from '@/components/tab/TabContext';
import { trackEvent, trackError } from '@/service/omega/tianyan';
import { firstInteractiveDurationStart, firstInteractiveDurationSend } from '@/utils';
import { unregister } from './service-worker';

unregister();

// 统计用户页面访问情况
(function () {
  try {
    // 屏蔽图片右击保存
    document.oncontextmenu = function (e) {
      // @ts-ignore
      if (e?.target?.tagName === 'IMG') {
        return false;
      }
    };

    window.onhashchange = () => {
      firstInteractiveDurationStart();
      firstInteractiveDurationSend();
    };
  } catch (error) {
    console.log(error);
  }
})();

(function () {
  const rawHistoryGo = window.history.go.bind(window.history);

  window.history.go = (delta, refresh = false) => {
    if (!delta) {
      rawHistoryGo(delta);
    } else if (!window?.history?.state?.CURRENT_ROUTE_TABS_INFO) {
      rawHistoryGo(delta);
    } else if (delta < 0) {
      const routeLength = parseInt(window?.history?.state?.CURRENT_ROUTE_TABS_INFO?.routes?.length);
      let targetIndex = -1;
      if (Math.abs(delta) >= routeLength) {
        rawHistoryGo(delta);
      } else {
        targetIndex = routeLength - 1 + delta;

        const customEvent = new CustomEvent<{ targetPathInCurrentPath: string }>(
          'PROCESS_GO_AND_BACK',
          {
            detail: {
              targetPathInCurrentPath:
                window?.history?.state?.CURRENT_ROUTE_TABS_INFO?.routes?.[targetIndex]?.path,
            },
          }
        );
        if (refresh) {
          const dropPathInfo =
            window?.history?.state?.CURRENT_ROUTE_TABS_INFO?.routes?.[targetIndex];
          const microAppMap = window?.BASE_TABS_STATE?.microAppMap;
          if (microAppMap?.[getMicroAppName(dropPathInfo?.path)]?.update) {
            // 菜单加上route后需优化
            let dropRouteKey = '';
            if (dropPathInfo?.route) {
              dropRouteKey = dropPathInfo?.route;
            } else {
              const hashPath = dropPathInfo?.path?.split('#')[1] || '';
              const ruleRouteData = window.MICRO_ALL_ROUTES?.find(item => {
                return checkUrlAndRouteIsSame(item?.path, hashPath);
              });
              dropRouteKey = ruleRouteData?.path;
            }
            microAppMap?.[getMicroAppName(dropPathInfo?.path)]
              ?.update?.({ value: dropRouteKey })
              .then(() => {
                window.dispatchEvent(customEvent);
              });
          } else {
            window.dispatchEvent(customEvent);
          }
        } else {
          window.dispatchEvent(customEvent);
        }
      }
    } else {
      rawHistoryGo(delta);
    }
  };

  window.history.back = window.history.go.bind(window.history, -1);
})();

function bootstrap() {
  const preDate = new Date().getTime();
  window.performanceMainReactRender = preDate;
  window.performanceMainReactDuration = preDate - (window.performanceHead || 0);
  (window as any).initSystemTime = preDate; // 标记系统初始化时间,用于后续统计各阶段加载时长
  setSessionStorage('isMultiTabsGray', true);
  import('./MainApp')
    .then(async ({ renderMainApp }) => {
      await renderMainApp();
      const nowDate = new Date().getTime();
      const time = nowDate - preDate;
      console.log('统计Multi Tabs Gray基座加载时长', time);
      // 刚进来的页面展示上报

      trackEvent(TRACK_PAGELOAD_TIME, '统计Multi Tabs Gray基座加载时长', { time });
    })
    .catch(err => {
      console.log(err);
      trackError(TRACK_ERROR_NAME, '【catch error】index 加载 MainAppGray.js', {
        err: JSON.stringify(err),
      });
    });
}

// fixed the same issue https://github.com/umijs/qiankun/issues/1804
// 子应用本地开发进行热更新时，出现异常
window.__REACT_ERROR_OVERLAY_GLOBAL_HOOK__ = {};

bootstrap();
