import React, { useMemo } from 'react';
import { Image } from 'antd';

interface FeatureContentInterface {
  data: any;
}

function FeatureContent(props: FeatureContentInterface) {
  const { data } = props;

  const contentList = useMemo(() => {
    return (data?.content || '').split('\n');
  }, [data.content]);
  return (
    <>
      {contentList.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
      {data.relatePicUrl && (
        <div style={{ margin: '10px 0' }}>
          <Image width={'100%'} height={'100%'} src={data.relatePicUrl} />
        </div>
      )}
      {data.link && (
        <div>
          <a href={data.link}>可点击链接查看培训资料，了解详细功能点说明</a>
        </div>
      )}
    </>
  );
}

export default FeatureContent;
