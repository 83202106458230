import qs from 'qs';
import { getSessionStorage } from '@/helper/storage';
import { FE_ENV_BRANCH } from '@/constant/storage';
import { shouldAddProxyPrefix } from '@/constant/frame-work-proxy-key';
import { trackError } from '@/service/omega/tianyan';
import { TRACK_ERROR_NAME } from '@/constant';

export default (path) => {

  try {
    if (!path || typeof path !== 'string') return '';
    const searchs = qs.parse(location.search.slice(1));
    const sessionBranch = getSessionStorage(FE_ENV_BRANCH) || 'master';

    if (!Object.keys(searchs).length && sessionBranch && sessionBranch !== 'master') {
      searchs.branch = sessionBranch;
    }


    if (path.indexOf('#')) {
      const pathParams = path.split('#');
      if (pathParams[1] !== undefined) {

        return shouldAddProxyPrefix(`${pathParams[0]}${qs.stringify(searchs) && `?${qs.stringify(searchs)}`}#${pathParams[1]}`);
      }
      return shouldAddProxyPrefix(`${pathParams[0]}${qs.stringify(searchs) && `?${qs.stringify(searchs)}`}`);
    }

    return shouldAddProxyPrefix(path);
  } catch (err) {
    trackError(TRACK_ERROR_NAME, '【catch error】formatLocation Path Function', {
      err: JSON.stringify(err),
    });
    return '';
  }

}
