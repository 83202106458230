import { omegaTrackEvent } from '@/service/omega/tianyan';
import { TAB_ACTIVE, TAB_INACTIVE } from '@/constant/index';
import { getMicroAppName, setBreadName } from '@/components/tab/TabContext';
import { shouldAddProxyPrefix } from '@/constant/frame-work-proxy-key';
import userStore from '@/store/user';
declare type TabStatus = typeof TAB_ACTIVE | typeof TAB_INACTIVE;

export declare type Route = {
  name: string;
  path: string;
  route?: string;
}

export class Tab {
  appName: string;
  status: TabStatus = TAB_INACTIVE;
  routes: Route[] = [];
  baseRoute: Route | null = null;
  activeRoute: Route | null = null;
  dropRoute: Route[] = [];
  private readonly closable: boolean;

  constructor(route: Route) {
    if (route?.path) route.path = shouldAddProxyPrefix(route.path)
    this.status = TAB_INACTIVE;
    this.routes.push(setBreadName(route));
    this.baseRoute = route;
    this.activeRoute = route;
    this.appName = getMicroAppName(route.path);
    this.closable = route.path !== userStore.HOME_PAGE_PATH
  }

  addRoute(route: Route) {
    const index = this.routes?.findIndex(item => item.path === route?.path);
    if (index < 0) {
      // this.dropRoute = [...this.routes];
      this.routes.push(setBreadName(route));
    } else {
      const routes = this.routes;
      this.routes = routes.slice(0, index + 1);
      this.dropRoute = routes.slice(index + 1);
    }
    this.activeRoute = route;
  }

  jumpToRoute(routePath: string) {
    const index = this.routes?.findIndex(item => item.path === routePath);
    if (index !== -1) {
      this.activeRoute = this.routes[index];
      const routes = this.routes;
      this.routes = routes.slice(0, index + 1);
      this.dropRoute = routes.slice(index + 1)
      return true;
    } else {
      return false;
    }
  }

  findRoute(routePath: string) {
    const index = this.routes?.findIndex(item => item.path === routePath);
    if (index !== -1) {
      return this.routes[index];
    } else {
      return false;
    }
  }

  activeTab() {
    this.status = TAB_ACTIVE;
    window.BASE_TABS_CURRENT_TAB = this;
    // freight_parter_huoyun_menu_ck -> 菜单点击埋点
    omegaTrackEvent('freight_parter_huoyun_menu_ck', '菜单点击', {
      menu_name: this.baseRoute?.name
    });
  }

  inactiveTab() {
    this.status = TAB_INACTIVE;
  }

  getClosable() {
    return this.closable;
  }
}

const tabs: Record<string, Tab> = {}


export default tabs;
