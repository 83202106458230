import { post } from '@/service/request';

// 获取未读消息数量
export const getTotalUnReadCountApi = data =>
  post('trans?action=notify_notifyPushInboxService_queryTotalUnReadCount', data);

// 获取未读消息列表
export const getNoticeListApi = data =>
  post('/trans?action=notify_notifyPushInboxService_queryInboxList', data);

// 一键已读
export const oneHasReadApi = data =>
  post('/trans?action=notify_notifyPushInboxService_oneHasRead', data);

// 已读消息
export const hasReadApi = data =>
  post('/trans?action=notify_notifyPushInboxService_updatePushStatus', data);

// 获取新功能通知列表
export const getNewFeatureList = data =>
  post('/trans?action=notify_notifyPushInboxService_queryInboxListForPop', data);
