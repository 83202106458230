module.exports = new Promise(resolve => {
    const timestamp = Math.floor(Date.now() / 60 / 1000);
    const script = document.createElement('script');
    const first = window.location.host.split('.')[0];
      if (first.includes('-pre')) {
        script.src = '//hy-static-pre.intra.xiaojukeji.com/federation-components/remoteEntry.js?timestamp=' + timestamp;
      } else {
        script.src = '//hy-static.xiaojukeji.com/federation-components/remoteEntry.js?timestamp=' + timestamp;
      }
    script.onload = () => {
      const proxy = {
        get: (request) => window['cx_mf_compo'].get(request),
        init: (arg) => {
          try {
            return window['cx_mf_compo'].init(arg)
          } catch(e) {
            console.log('remote container already initialized')
          }
        }
      };
      resolve(proxy);
    }
    document.head.appendChild(script);
  });