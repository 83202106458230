import { PROJECT_DOMAIN } from '@/constant/domain';
import { MICRO_BASE_DATA } from '@/constant/storage';
import { getStorage } from './storage';
import { APP_DEPLOY_ENV_ENUM } from '@/constant/enum';

export const isProduction = PROJECT_DOMAIN.PROD.includes(location.hostname); // 此处不考虑历史逻辑 env 的取值，统一兼容掉
export const isPre = PROJECT_DOMAIN.PRE.includes(location.host);
export const isSim = PROJECT_DOMAIN.SIM.includes(location.host);
export const isLocal = location.host.includes('local');
export const isProdOrPre = isProduction || isPre;

export const env = isProduction ? 'production' : isPre ? 'pre' : 'sim';
console.log('IS_OE_BUILD_SIM', process.env.IS_OE_BUILD_SIM);
export const isTestOpen = location.search.includes('isTestOpen') || location.hash.includes('isTestOpen') || false; // 测试环境是否打开埋点开关，正常一定是false，测试时可以打开

export const APP_ENV = (() => {
  if (isProduction) return APP_DEPLOY_ENV_ENUM.PROD;
  if (isPre) return APP_DEPLOY_ENV_ENUM.PRE;
  if (isSim) return APP_DEPLOY_ENV_ENUM.SIM;
  if (isLocal) return APP_DEPLOY_ENV_ENUM.SIM;
})();

export const passportConfig = {
  appId: 121182,
  roleId: 2416,
  // 生产环境使用线上环境，线下sim和本地开发都是要测试环境
  domain: process.env.IS_OE_BUILD_SIM
    ? 'http://passport-test.didichuxing.com/static/pc-login/3.0.6/index.html'
    : 'https://passport.didichuxing.com/common/pc-login/3.0.1/index.html',
  getwayCode: {
    NO_LOGIN: 999312, // 未登录
    NO_AUTH: 999318, // 没有权限
  },
  notLoginMessage: '当前账号登录异常，请重新登录或联系公司负责人、客服',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'x-embed-sys-id': 'citra',
  },
};

export const stableApiGateway = '//hyworkserver-test.intra.xiaojukeji.com/gateway';
export const osimApiGateway = '//hyworkserver-test.intra.xiaojukeji.com/gateway';

// export const stableApiGateway = '//citra-stable.intra.xiaojukeji.com/gateway';
// export const osimApiGateway = '//citra-osim.intra.xiaojukeji.com/gateway';

const handleGateway = () => {
  return window.location.host === 'partner.xiaojukeji.com'
    ? 'citra-596.xiaojukeji.com'
    : 'citra.xxhuoyun.com';
};

// 预发和线上环境，独立出来是为了防止测试环境切线上服务误操作数据
const prodApiGatewayMap = {
  pre: '//hyworkserver-pre.xiaojukeji.com/gateway',
  production: `//${handleGateway()}/gateway`,
};

const handleOnlineOriginUrl = () => {
  return window.location.host === 'partner.xiaojukeji.com'
    ? '//partner.xiaojukeji.com'
    : '//partner.xxhuoyun.com';
};

export const ORIGIN_URL = !isProdOrPre
  ? `//${location.host}`
  : isPre
  ? '//partner-pre.xiaojukeji.com'
  : handleOnlineOriginUrl();
// 区分 是内部用户还是真实用户
export const userStatus = 'normal';

export const osimValue = getStorage(MICRO_BASE_DATA)?.osimValue || '000';

export const osimHeader =
  env === 'sim' && osimValue !== 'stable'
    ? {
        'didi-header-sim-cluster': `zjy-dev-osim${osimValue}-v`,
        'nuwa-wings': `env=osim${osimValue}-v`,
      }
    : {};

export const API_URL = isProdOrPre
  ? prodApiGatewayMap[env]
  : osimValue === 'stable'
  ? stableApiGateway
  : osimApiGateway;


const ownDomain = 'xiaojukeji', openDomain = 'xxhuoyun';

const onlineDomainGateWayMap = {
  [ownDomain]: '//hyworkserver.xiaojukeji.com/gateway',
  [openDomain]: '//gateway.xxhuoyun.com/gateway'
}


const hostname  = window.location.host.split('.')[1];


export const FREIGHT_GATEWAY_API_URL_ENUM = {
  production: onlineDomainGateWayMap[hostname] || onlineDomainGateWayMap[ownDomain], // default xiaojukeji
  pre: '//hyworkserver-pre.xiaojukeji.com/gateway',
  sim: '//hyworkserver-test.intra.xiaojukeji.com/gateway'
}

export const FREIGHT_GATEWAY_API_URL = FREIGHT_GATEWAY_API_URL_ENUM[env]


