import React from 'react';
import {
  loadMicroApp,
  runAfterFirstMounted,
  setDefaultMountApp,
  start,
  prefetchApps,
} from 'qiankun';
import { getMicroAppName } from '@/components/tab/TabContext';
import { fetchApplications } from '@/configs/apps';
import { MICRO_BASE_DATA } from 'src/constant/storage';
import { setStorage } from '@/helper/storage';
import { API_URL, env, osimValue, osimHeader, FREIGHT_GATEWAY_API_URL } from '@/helper/env';
import { login } from '../login';
import userStore from '@/store/user';
import noticeStore from '@/store/notice';
import formatLocationPath from '@/utils/formatLocationPath';
import { message, notification } from '@/helper/robot';
import { TRACK_APP_SOURCE_ERROR_NAME } from '@/constant';
// import { PROJECT_USER_KEY } from '@/constant/storage';
import { asyncAppendScriptElement, cdnMap } from './util';
import { lifeCycle } from './util/lifeCycle';
import dispatchRenameBread from '@/utils/dispatchMicroBreadCrumbListName';
import { pushStateWithDropCache, replaceStateWithDropCache } from '@/components/tab/TabContext';
import {
  setBootstrapMaxTime,
  setMountMaxTime,
  setUnmountMaxTime,
  setUnloadMaxTime,
  addErrorHandler,
} from 'single-spa';
import raven, { trackEvent, trackError, omegaTrackEvent } from '@/service/omega/tianyan';
import fenghuo from '@/service/omega/fenghuo';
import { passportConfig } from '@/helper/env';
import getMicroManifest from './godzilla';
import { Modal } from 'antd';
import FeatureContent from '@/components/notice/components/featureContent';
import { downloadFile } from '@/utils';
import { getGatewayGrayConfig  } from '../apollo/gray';

// 初始化基座特征参数到storage（由于通过基座从子应用mount传过去的数据为异步数据，慢于子应用同步任务，因此才做了这个）
setStorage(MICRO_BASE_DATA, {
  portalName: 'freight-open',
  osimValue,
  osimHeader,
  gateway: FREIGHT_GATEWAY_API_URL,
  env,
});

function openFeatureModal(featureList, index) {
  const modal = Modal.confirm({
    icon: null,
    title: featureList[index]?.title,
    width: '50%',
    content: <FeatureContent data={featureList[index]}></FeatureContent>,
    cancelButtonProps: {
      type: 'primary',
    },
    cancelText: '下载文档',
    okText: '我知道了',
    onCancel() {
      if (featureList[index]?.documentUrl) {
        downloadFile(featureList[index]?.documentUrl, '功能文档');
      } else {
        message.info('暂无文档');
      }

      openFeatureModal(featureList, index + 1);
    },
    onOk() {
      modal.destroy();
      openFeatureModal(featureList, index + 1);
    },
  });
}

const jumpByType = (
  jumpInfo: {
    path: string;
    query?: {
      [key: string]: any;
    };
    params?: {
      [key: string]: any;
    };
  },
  type: 'push' | 'replace'
) => {
  const params = {
    ...(jumpInfo.query || {}),
    ...(jumpInfo.params || {}),
  };
  const curPath = formatLocationPath(jumpInfo.path);
  if (!type || type === 'push') {
    return window.history.pushState(params, '', curPath);
  }
  return window.history.replaceState(params, '', curPath);
};

/**
 * 获取state
 * @returns
 */
export const getState = () => {
  raven.getMicroAppName = getMicroAppName;
  return {
    portalName: 'freight-open',
    jumpByType,
    osimHeader,
    passportConfig,
    message,
    notification,
    login,
    setRouterList: routerList => {
      window.MICRO_ALL_ROUTES = [...(window.MICRO_ALL_ROUTES || []), ...routerList];
    },
    trackTool: raven,
    fenghuo,
    getUserStore: () => userStore,
    getCurrentGateway: () => FREIGHT_GATEWAY_API_URL,
    // 网关灰度切换方法，各子应用使用
    grayGatewayRequest: (requestUrl, options) => {
      // 背景：前端新接口调用传nodeGateway = true, 不走灰度判断逻辑，直接走node网关。
      // 作用：避免新接口还需在ab上配置灰度切换逻辑
      const { nodeGatewayRequest = false } = options || {}
      if (nodeGatewayRequest === true) {
        return requestUrl.replace(API_URL, FREIGHT_GATEWAY_API_URL)
      }
      const grayKey = window.location.hash.replace('#', '/partner')
      // 针对特定页面控制, 便于收集有问题页面
      console.log(window.pageGateWayGrayConfig, 'page_gray_config', grayKey)
      const { pageGateWayGrayConfig } = window
      const grayConfArr = Object.keys(pageGateWayGrayConfig || {});
      const notHit = grayConfArr.some(key => grayKey.startsWith(key) && pageGateWayGrayConfig[key] === false);
      if(notHit) return requestUrl;
      const isHit = grayConfArr.some((key) => grayKey.startsWith(key) && pageGateWayGrayConfig[key])
      // 命中网关切流灰度
      if (isHit) {
        return requestUrl.replace(API_URL, FREIGHT_GATEWAY_API_URL)
      }
      return requestUrl
    },
    getGatewayOfPlatform: () => FREIGHT_GATEWAY_API_URL,
    getTotalUnReadCount: noticeStore.getTotalUnReadCount,
    dispatchRenameBread,
    pushStateWithDropCache,
    replaceStateWithDropCache,
    wsgConfig: {
      // 安全加签使用的，bizId找的安全的冯威申请的
      open: true,
      bizId: '2a6ee94215973b235e9d6878b009eedd',
      handleParams: options => {
        /**
         * 货运的请求post的params参数，没有被网络库正常的给加签带给安全
         */
        try {
          const headers = options?.headers || {};
          if (Object.keys(headers).includes('x-citra-source')) {
            const url = options?.url ? options.url : '';
            const urlList = url.includes('action=') ? url.split('action=') : url.split('/');
            const curUrl = urlList[urlList.length - 1];

            const params: any = {};
            if (curUrl) {
              params.action = curUrl;
            }
            return params;
          }
        } catch (error) {
          return {};
        }
      },
    },
    omegaTrackEvent,
  };
};

export const initQiankunService = async () => {
  setBootstrapMaxTime(15000);
  setMountMaxTime(15000);
  setUnmountMaxTime(15000);
  setUnloadMaxTime(15000);

  addErrorHandler(err => {
    // @ts-ignore
    const { open_company_id, phone, uid, user_name } = window?.Fenghuoconfig || {};
    trackEvent('MICRO_APP_GLOBAL_LIFECYCLE_ERROR', '', {
      name: err.appOrParcelName,
      err,
      open_company_id,
      phone,
      uid,
      user_name,
    });
  });

  /**
   * Step1 初始化应用（可选）
   */
  try {
    const { appsEntryManifests, localMenus } = await fetchApplications();
    const apps = appsEntryManifests.filter(item => item.name !== 'main');

    const userInit = userStore.init(localMenus);

    const [appConfigList, _, page_gray_config] = await Promise.all([getMicroManifest(apps), userInit, getGatewayGrayConfig()]);
    window.pageGateWayGrayConfig = page_gray_config

    const appsEntryManifestMap = {};

    appConfigList &&
      appConfigList.forEach(
        item =>
          (appsEntryManifestMap[item.app.name] = {
            name: item.app.name,
            ...item.config,
          })
      );

    window.appsEntryManifestMap = appsEntryManifestMap;

    prefetchApps(
      appConfigList &&
        appConfigList.map(item => {
          return {
            name: item.app.name,
            entry: {
              html: `<div id="${item.app.name}"></div>`,
              scripts: item.config.scripts,
              styles: item.config.styles,
            },
          };
        })
    );
    // @ts-ignore
    window.portalState = getState();

    let currentPath = window.location.pathname + window.location.hash;

    if (window.location.pathname === '/' || /\/proxy-boss\/$/.test(window.location.href)) {
      currentPath = userStore.HOME_PAGE_PATH;
    }

    setDefaultMountApp(formatLocationPath(currentPath));

    start();

    runAfterFirstMounted(async () => {
      // 第一个子应用加载完成
      const head = document.head || document.getElementsByTagName('head')[0];
      const { appId } = passportConfig;
      asyncAppendScriptElement(head, cdnMap['waterMark'], () => {
        const { userId, name } = userStore.userInfo;
        // @ts-ignore
        window.waterMark({
          systemId: 'hyt' + appId,
          userId: name + '-' + userId,
          textStyle: 'rgba(0,0,0,0.03)',
        });
      });

      // 检查是否有新功能通知，如有则打开弹窗
      await noticeStore.getNewFeatureList();
      if (noticeStore.hasNewFeature) {
        openFeatureModal(noticeStore.featureList, 0);
      }
    });
  } catch (e) {
    trackError(TRACK_APP_SOURCE_ERROR_NAME, '基座初始化子应用资源失败 catch error', {
      error: JSON.stringify(e),
      error_level: 'P0',
      error_type: '0',
    });
  }
};

export const loadApp = ({ appName, appsEntryManifestMap }) => {
  // @ts-ignore
  const portalState = window.portalState;
  console.log('******state', portalState);
  const subAppWrapper = document.getElementById('subapp-viewport');
  const currentAppWrapper = document.createElement('div');
  subAppWrapper.appendChild(currentAppWrapper);

  if (!(appName && appName in appsEntryManifestMap)) {
    appName = userStore.HOME_PAGE_APP_NAME;
  }
  const microApp = loadMicroApp(
    {
      name: appName,
      entry: {
        html: `<div id="${appName}"></div>`,
        scripts: appsEntryManifestMap[appName].scripts,
        styles: appsEntryManifestMap[appName].styles,
      },
      container: currentAppWrapper,
      props: portalState,
    },
    undefined,
    lifeCycle
  );

  microApp.loadPromise.catch(err => {
    // @ts-ignore
    const { open_company_id, phone, uid, user_name } = window?.Fenghuoconfig || {};

    trackEvent('MICRO_APP_LOAD_LIFECYCLE_ERROR', err.appOrParcelName, {
      err,
      open_company_id,
      phone,
      uid,
      user_name,
    });
  });
  microApp.bootstrapPromise.catch(err => {
    // @ts-ignore
    const { open_company_id, phone, uid, user_name } = window?.Fenghuoconfig || {};

    trackEvent('MICRO_APP_BOOTSTRAP_LIFECYCLE_ERROR', err.appOrParcelName, {
      err,
      open_company_id,
      phone,
      uid,
      user_name,
    });
  });
  microApp.mountPromise.catch(err => {
    // @ts-ignore
    const { open_company_id, phone, uid, user_name } = window?.Fenghuoconfig || {};

    trackEvent('MICRO_APP_MOUNT_LIFECYCLE_ERROR', err.appOrParcelName, {
      err,
      open_company_id,
      phone,
      uid,
      user_name,
    });
  });

  return { currentAppWrapper, microApp };
};
