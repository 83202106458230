import Shanhaiguan from '@didi/shanhaiguan'
import { isProduction, isTestOpen, env } from '@/helper/env';
import omegaTracker from '@didi/omega-tracker-lite';

const Omega = (isTestOpen || isProduction) && omegaTracker.getTracker({
  appKey: 'omegac3c3073c8e',
  autoError: false,
  autoSendPageView: false,
  hashRouterEnable: false,
  browserRouterEnable: false,
  autoPerformance: false,
  autoClick: false,
});


export const omegaTrackEvent = (eventName, eventLabel, attrs) => {
  if (!isTestOpen && !isProduction) return
  if (typeof eventLabel !== 'string') {
    // 参数判空
    attrs = eventLabel;
  }
  Omega?.trackEvent(eventName, eventLabel || eventName, { ...(window as any).FREIGHT_OMEGA_CONFIG, ...attrs })

};

export const omegaSetConfig = (config) => {
  if (!isTestOpen && !isProduction) return
  (window as any).FREIGHT_OMEGA_CONFIG = {
    ...(window as any).FREIGHT_OMEGA_CONFIG,
    ...config
  }
}


const ravenInstance: any = {}
const shanhaiguan = new Shanhaiguan({
  env,
  appId: isProduction ? '1477' : '1393',
  omegaKey: 'omegac3c3073c8e'
})
let isTianyanInit = false

export const initRavenConfig = (params) => {
  if (isTianyanInit) return

  shanhaiguan.setConfig({
    env: isProduction ? 'production' : 'sim',
    ...params
  })
  shanhaiguan.initMonitor();

  (window as any).SHANHAIGUNA = shanhaiguan;

  const readyObejct = {
    raven: shanhaiguan.raven,
    shanhaiguan,
    getMicroAppName: shanhaiguan.getMicroAppName,
    onRequestOK,
    onResponseOK,
    onResponseFail,
    onRequestInterceptor,
    onResponseInterceptor,
    retryRequestInterceptor,
    retryResponseInterceptor,
  }
  Object.keys(readyObejct).forEach(key => {
    ravenInstance[key] = readyObejct[key]
  })
  isTianyanInit = true
}

export const onRequestOK = shanhaiguan.onRequestOK

export const onResponseOK = shanhaiguan.onResponseOK

export const onResponseFail = shanhaiguan.onResponseFail

export const trackDialogError = shanhaiguan.trackDialogError

export const reportRecord = shanhaiguan.reportRecord

// 兼容网络库的 request interceptor
export const onRequestInterceptor = shanhaiguan.onRequestInterceptor

// 兼容网络库的 response interceptor
export const onResponseInterceptor = shanhaiguan.onResponseInterceptor

// 兼容网络库的重试 interceptor
export const retryRequestInterceptor = shanhaiguan.retryRequestInterceptor
export const retryResponseInterceptor = shanhaiguan.retryResponseInterceptor

export const trackError = (...rest) => {
  shanhaiguan?.raven?.trackError(...rest);
}

export const trackEvent = (...rest) => {
  shanhaiguan?.raven?.trackEvent(...rest);
}

export const setAttrs = (attrs) => {
  shanhaiguan?.raven?.setAttrs(attrs);
}

export const setConfig = (attrs) => {
  shanhaiguan?.raven?.setConfig(attrs);
}

export default ravenInstance
