import { getTotalUnReadCountApi, getNewFeatureList } from '@/components/notice/api';
import { makeAutoObservable } from 'mobx';
import userStore from '../user';
import { get30DaysTime } from '@/utils';

class Notice {
  constructor() {
    makeAutoObservable(this);
  }

  // 消息数量
  count = 0;

  // 是否有新功能通知
  hasNewFeature = true;

  // 新功能弹窗列表 featureList
  featureList = [];

  getTotalUnReadCount = async () => {
    const { data } = await getTotalUnReadCountApi({
      businessId: 3,
      phone: userStore.userInfo.phone,
      ...get30DaysTime(),
    });
    this.count = data;
  };

  getNewFeatureList = async () => {
    const { data } = await getNewFeatureList({
      businessId: 3,
      phone: userStore.userInfo.phone,
      // categoryCode: '3',
      ...get30DaysTime(),
    });
    this.featureList = data || [];
    this.hasNewFeature = !!(data || []).length;
  };
}

export default new Notice();
