import React from 'react';
import { message as antMessage, notification as antNotification, Button } from 'antd';
import { getStorage, setStorage } from '@/helper/storage';

import { PROJECT_USER_KEY } from '@/constant/storage';
import { isProduction } from '@/helper/env';
import { formatTime } from '@/utils';
import { post } from '@/service/request';
let isRobotSDKInit = false;

const checkShowStatus = () => {
  const times = getStorage('robot');
  const curDate = formatTime(new Date(), 'YYYYMMDD');
  if (times) {
    let [time, all = 0] = times?.split('-');
    time = `${time}`;
    if (time !== curDate) {
      if (all < 3) {
        all++;
        setStorage('robot', `${curDate}-${all}`);
        (window as any).__robot_front_sdk__.open();
      }
    }
  } else {
    setStorage('robot', `${curDate}-1`);
    (window as any).__robot_front_sdk__.open();
  }
};
export const initRobotSDK = () => {
  // if (isRobotSDKInit || !isProduction) return;
  if (isRobotSDKInit) return;
  try {
    isRobotSDKInit = true;
    const { phone } = getStorage(PROJECT_USER_KEY) || {};
    const RobotSDKReady = function (readyCallback) {
      if (readyCallback && typeof readyCallback == 'function') {
        if (
          (window as any).__robot_front_sdk__ &&
          typeof (window as any).__robot_front_sdk__ === 'object'
        ) {
          readyCallback();
        } else {
          document.addEventListener(
            'RobotSDKReady',
            function () {
              readyCallback();
            },
            false
          );
        }
      }
    };
    RobotSDKReady(function () {
      (window as any).__robot_front_sdk__.init({
        id: '37150b77909b11ed888008c0eb39ddfc',
        username: phone || 'default_user',
        show: true,
        fullScreen: false,
        width: 400,
        iconDragable: true,
        iconUrl: 'https://s3-cxyx.didistatic.com/cy-static/robot-sdk/icon.png',
        feedCallBack: feedback,
        isHy: true,
        floatTab: [
          {
            text: '没有找到需要的问题？点击反馈',
            callback: () => {
              console.warn('反馈');
            },
          },
        ],
      });

      checkShowStatus();
    });
  } catch (e) {
    console.error(e);
  }

  // try {
  //   if (!window?.MutationObserver) return;
  //   const observer = new MutationObserver((mutationList, observer) => {
  //     mutationList.forEach(mutation => {
  //       switch (mutation.type) {
  //         case 'childList':
  //           if (mutation?.addedNodes[0]?.firstChild) {
  //             const firstDom: any = mutation?.addedNodes[0]?.firstChild;
  //             if (firstDom?.nodeType === 1 && firstDom?.classList?.contains('ant-message-notice-content')) {
  //               if (
  //                 firstDom.firstChild?.classList?.contains('ant-message-error') &&
  //                 firstDom.firstChild?.children?.length === 2
  //               ) {
  //                 const button = document.createElement('button');
  //                 button.classList.add('ant-btn');
  //                 button.classList.add('ant-btn-link');
  //                 button.innerHTML = '立即反馈';
  //                 button.onclick = () => {
  //                   (window as any).__robot_front_sdk__.open({
  //                     triggerFloatTab: true,
  //                     tabIndex: 0,
  //                   });
  //                 };
  //                 firstDom.firstChild.appendChild(button);
  //               }
  //             }
  //           }
  //           break;
  //       }
  //     });
  //   });

  //   const el = document.querySelector('body');
  //   const options = {
  //     childList: true,
  //     attributes: true,
  //     subtree: true,
  //   };
  //   observer.observe(el, options);
  // } catch (e) {
  //   console.error(e);
  // }
};

/**
 * @todo 把error ts类型搞过来
 */

export const message = antMessage;
export const proxyAntMessage = () => {
  try {
    if (typeof Proxy !== 'function' || !isProduction) return;
    message.error = new Proxy(antMessage.error, {
      apply(target: any, thisArg, argumentsList: any) {
        // 新增天眼指标
        try {
          const content =
            typeof argumentsList[0] === 'string'
              ? argumentsList[0]
              : typeof argumentsList[0] === 'object'
              ? argumentsList[0].content
              : '';
          trackDialogError({ content });
        } catch (e) {
          console.warn(e);
        }

        // 拦截新增按钮
        if (argumentsList && typeof argumentsList[0] === 'string' && !argumentsList[1]) {
          const remark = argumentsList[0];
          argumentsList[0] = (
            <span>
              {argumentsList[0]}
              <Button
                onClick={async () => {
                  await feedback({ content: '用户在报错弹窗反馈，报错内容：' + remark });
                  message.success('反馈成功, 我们会尽快处理');
                  // (window as any).__robot_front_sdk__.open({
                  //   triggerFloatTab: true,
                  //   tabIndex: 0,
                  // });
                }}
                type="link"
              >
                反馈问题
              </Button>
            </span>
          );
        }
        return target(...argumentsList);
      },
    });
  } catch (e) {
    console.error(e);
  }
};

export const notification = antNotification;
export const proxyAntNotification = () => {
  try {
    if (typeof Proxy !== 'function' || !isProduction) return;
    notification.error = new Proxy(antNotification.error, {
      apply(target: any, thisArg, argumentsList: any) {
        try {
          const content =
            typeof argumentsList[0] === 'object' && argumentsList[0].message
              ? argumentsList[0].message
              : '';
          trackDialogError({ content });
        } catch (e) {
          console.warn(e);
        }

        if (argumentsList && typeof argumentsList[0] === 'object') {
          const remark = argumentsList[0].message ? argumentsList[0].message : '';
          argumentsList[0].btn = (
            <span>
              <Button
                onClick={async () => {
                  await feedback({ content: '用户在报错弹窗反馈，报错内容：' + remark });
                  message.success('反馈成功, 我们会尽快处理');
                }}
                type="link"
              >
                反馈问题
              </Button>
            </span>
          );
        }
        return target(...argumentsList);
      },
    });
  } catch (e) {
    console.error(e);
  }
};

export const feedback = function (opt?) {
  console.log('feedback', opt);
  return post('/trans?action=platform_crm_WorkQuestionMisService_submitQuestion', {
    picUrl: opt?.picture?.join(';'),
    question: opt?.description,
    osType: 3, // PC系统
    source: 3  // 货运通端
  });
};

// export const feedback = async ({ content }) => {
// const { uid, name, phone } = getStorage(PROJECT_USER_KEY) || {};
// const { groupName, popularName } = getStorage(PROJECT_COMPANY_KEY) || {};
// const { mozId } = getStorage(PROJECT_STORE_KEY) || {};
// const feedbackUrl = 'https://vulcan.chengxinyouxuan.com/api/feedback/work/create'; // todo 需要替换 by ethanqiang
// const { timestamp } = reportRecord({
//   eventType: 1,
//   traceId: null,
//   reqUrl: window.location.href,
//   respHttpStatus: null,
//   respErrno: null,
//   extInfo: null,
// });
// let breadcrumb;
// try {
//   const routes = window.BASE_TABS_STATE?.currentTab?.routes || [];
//   breadcrumb = routes.map(item => item.name).join('/');
// } catch (e) {
//   breadcrumb = '';
// }

// return post(feedbackUrl, {
//   uid,
//   ua: window.navigator.userAgent,
//   phone,
//   type: '0',
//   supplier: phone,
//   info: content,
//   playbackId: timestamp,
//   extInfo: {
//     breadcrumb,
//     phone,
//     name,
//     mozId,
//     url: window.location.href,
//     popularName,
//     groupName,
//     playbackUrl: `${isProduction ? PLAYBACK_PLATFORM_URL : PLAYBACK_TEST_PLATFORM_URL
//       }uid=${uid}&playbackId=${timestamp}`,
//   },
// });
// };

// proxyAntMessage();
// proxyAntNotification();
