import { apolloRequestService } from '@/service/request';
import { getStorage } from '@/helper/storage';
import { PROJECT_STORE_KEY, MICRO_BASE_DATA } from '@/constant/storage'
import { APOLLO_CALLER_NAME } from '@/constant';
import { isProduction, isPre } from '@/helper/env';

const CURRENT_APP = 'main'

const grayEnv = getStorage(MICRO_BASE_DATA)?.env || 'sim'

export const getApolloConfigs = (config: { ns: string; name: string }) => {
  return apolloRequestService(
    {
      ...config,
      raw: true,
      __caller: APOLLO_CALLER_NAME,
    }, { useCache: false }
  );
};

/**
 *
 * @param name  灰度功能名称
 * @param type  灰度类型 默认白名单 white / black
 * @param config
 * @returns  promise
 */
export const getGray = async (
  name: string,
  type = 'white',
  config: { ns: string; name: string } = { ns: 'cangdian_fe', name: 'cangdian-apps-gray' }
) => {
  const { data } = await getApolloConfigs({ ...config });
  const { mozId }: any = getStorage(PROJECT_STORE_KEY) || {};
  const grayConfig = data[0]?.params?.[grayEnv]?.[`${type}_gray_resource`]?.[CURRENT_APP]?.[name];
  // 没有配置灰度策略 默认命中灰度全量展示
  if (!grayConfig || grayConfig?.length === 0) return type === 'white';
  return grayConfig?.includes(mozId);
}


/**
 * 网关切换灰度
 */
export const getGatewayGrayConfig = async () => {
  console.log('getGatewayGrayConfig')
  const apolloNs = 'citra-node-gateway-gray-config'
  const env = isProduction ? 'online' : isPre ? 'preonline' : 'stable' // 线上逻辑一套apollo控制，env保持统一
  // 忽略异常。 env【preonline，online，stable】代表不同环境配置
  const page_gray_config = await apolloRequestService({ ns: apolloNs, name: env })
    .then((res) => res?.data?.[0]?.params?.map)
    .catch(() => ({}))
  return page_gray_config
}