const { localStorage, sessionStorage } = window;

const setStorage = (key, data) => {
  if (!data || !key) {
    return;
  }

  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    console.log(e);
  }
};

const getStorage = key => {
  if (!key) {
    return null;
  }

  try {
    return JSON.parse(localStorage.getItem(key) ?? null);
  } catch (e) {
    console.log(e);
    return null;
  }
};

const removeStorage = key => {
  if (!key) {
    return;
  }
  localStorage.removeItem(key);
};

const setSessionStorage = (key, value) => {
  if (!value || !key) {
    return;
  }

  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.log(e);
  }
}

const getSessionStorage = (key) => {
  if (!key) {
    return null;
  }

  try {
    const res = sessionStorage.getItem(key);

    return res ? JSON.parse(res) : null;
  } catch (e) {
    console.log(e);
  }
}

const removeSessionStorage = key => {
  if (!key) {
    return;
  }
  sessionStorage.removeItem(key);
};

export { setStorage, getStorage, removeStorage, setSessionStorage, getSessionStorage, removeSessionStorage };
