export const unregister = () => {
  try {
    if ('serviceWorker' in navigator) {
      const serviceWorker = navigator.serviceWorker;
      navigator.serviceWorker.getRegistrations
        ? serviceWorker.getRegistrations().then(function (sws) {
            sws.forEach(function (sw) {
              sw && sw?.unregister?.();
            });
          })
        : serviceWorker.getRegistration &&
          serviceWorker.getRegistration().then(function (sw) {
            sw && sw?.unregister?.();
          });
    }
  } catch (error) {
    console.error(error);
  }
};
