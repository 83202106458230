import _ from 'lodash';
import { isProxyMode } from '@/constant/frame-work-proxy-key';
import { fetchGodzillaAppManifest, fetchOdinAppManifest } from './fetch-manifest';
// import { ApolloRequestService } from '@/service/apollo/get';

// const GODZILLA_APP_NAME_LIST = ['freight-open-micro-business']

// const getApolloGodzillaAppConfig = async () => {
//   try {
//     const res = await ApolloRequestService.fetch({
//       ns: 'cangdian_fe',
//       name: 'hy_godzilla_app',
//     });
//     return res?.data?.[0]?.params?.appList || [];
//   } catch (error) {
//     console.error(error);
//     return [];
//   }
// };

// export default async apps => {
//   // Apollo配置的新版本发布服务
//   const REMOTE_GODZILLA_APP_NAME_LIST = await getApolloGodzillaAppConfig();
//   // 全部的使用新版本服务的项目
//   const ALL_GODZILLA_APP_NAME_LIST = GODZILLA_APP_NAME_LIST.concat(REMOTE_GODZILLA_APP_NAME_LIST)
//   // 线上预发old+new
//   // proxy走old
//   // sim、local 也走old+new
//   if (isProxyMode) {
//     return Promise.all(apps.map(fetchOdinAppManifest)).then(data => data.filter(item => item));
//   }
//   const [godzillaAppList, odinAppList] = _.partition(apps, item =>
//     ALL_GODZILLA_APP_NAME_LIST.includes(item.projectName)
//   );
//   const fetchGodzilla = fetchGodzillaAppManifest(godzillaAppList);
//   const fetchOdin = Promise.all(odinAppList.map(fetchOdinAppManifest)).then(data =>
//     data.filter(item => item)
//   );
//   const godzillaManifestList = await fetchGodzilla;
//   const odinManifestList = await fetchOdin;
//   return odinManifestList.concat(godzillaManifestList || []);
// };

export default async apps => {
  // 线上预发old+new
  // proxy走old
  // sim、local 也走old+new
  if (isProxyMode) {
    return Promise.all(apps.map(fetchOdinAppManifest)).then(data => data.filter(item => item));
  }
  const godzillaManifestList = await fetchGodzillaAppManifest(apps);
  return godzillaManifestList || []
};
